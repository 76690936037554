<template>
  <div :class="containerClass">
    <div class="page-section">
      <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
        <h2 class="mb-0">
          My Skills

          <i class="material-icons icon-16pt clickable-item" v-b-popover.hover.top="`Observe and upload your skillset.`"
            >info</i
          >
        </h2>

        <div class="w-auto ml-sm-auto mb-sm-0" :class="{ 'd-flex': !isMobileScreen }">
          <div class="custom-select-icon" style="min-width: 150px">
            <b-form-select
              class="custom-select-icon__select"
              v-model="filters.title"
              :options="skillOptions"
              @change="onFilterApplied"
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
          <div :class="isMobileScreen ? 'mt-2 ml-auto' : 'ml-3'">
            <b-btn @click.prevent="openSkillModal()" variant="primary"
              ><i class="material-icons mr-1">videocam</i> ShowCase Skill</b-btn
            >
          </div>
        </div>
      </div>

      <page-separator
        :title="
          !isLoading ? `showing ${totalSkills ? `${pageFromCount}-${pageToCount}` : 0} of ${totalSkills} Skills` : ''
        "
      />
      <div class="page-section d-flex justify-content-center" v-if="!skills.length && !isLoading">
        <div class="card card-block card-stretch card-height col-md-8">
          <div class="card-body text-center">
            <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
            <h4>No Skills Found</h4>

            <p class="text-muted">
              Couldn't find any skills. Publish your skills practice video now and showcase your CNA skills!
            </p>
          </div>
        </div>
      </div>

      <b-skeleton-wrapper :loading="isLoading" v-else>
        <template #loading>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12" v-for="item in [1, 2, 3]" :key="item">
              <div class="card card-sm card--elevated p-relative">
                <span class="js-image">
                  <b-skeleton-img width="100%" class="pb-0 mb-0"></b-skeleton-img>
                </span>

                <div class="card-body">
                  <div class="d-flex">
                    <div class="flex">
                      <a class="card-title" href="#" @click.prevent><b-skeleton width="70%"></b-skeleton></a>
                    </div>
                    <a href="#" class="ml-4pt material-icons text-20 card-course__icon-favorite" @click.prevent
                      >more_vert</a
                    >
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row justify-content-end mr-2">
                    <b-skeleton width="20%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="row card-group-row mb-lg-12pt home-card">
          <div v-for="skill in skills" :key="skill.id" class="col-lg-4 col-md-4 col-sm-12 clickable-item mb-3">
            <div class="card card-sm card--elevated p-relative h-100 mb-0" @click.prevent="showSkillDetails(skill.id)">
              <span
                class="js-image d-flex justify-content-center clickable-item overlay"
                style="width: 100%"
                data-position="left"
                :data-height="168"
                @click.prevent="showSkillDetails(skill.id)"
              >
                <img
                  :src="skill.skill_thumbnail_url"
                  style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem"
                  alt="cna"
                  class="career-card-img"
                />
                <div class="overlay__content align-items-start justify-content-between p-2">
                  <div>
                    <md-icon>{{
                      skill.skill_video_access === SKILL_PRIVACY_OPTIONS.PUBLIC ? 'public' : 'lock_outline'
                    }}</md-icon>
                  </div>

                  <div v-if="skill.approval_status === APPROVAL_STATUS.APPROVED">
                    <md-icon class="text-success">check_circle</md-icon>
                  </div>
                </div>
              </span>

              <div class="card-body pb-0 mb-1">
                <div class="d-flex justify-content-between">
                  <div class="card-title">{{ skill.title }}</div>

                  <b-dropdown variant="flush" toggle-class="text-muted" no-caret right>
                    <template #button-content>
                      <i class="material-icons icon-24pt card-course__icon-favorite">more_vert</i
                      ><span class="sr-only">Menu</span>
                    </template>
                    <b-dropdown-item href="#" @click.stop="removeSkill(skill.id)"
                      ><i class="material-icons text-danger">remove_circle</i>Remove</b-dropdown-item
                    >
                    <b-dropdown-item href="#" @click.stop="updateSkillPrivacy(skill.id, skill.skill_video_access)"
                      ><i class="material-icons text-primary">update</i>Manage Privacy Settings</b-dropdown-item
                    >
                  </b-dropdown>
                </div>
              </div>
              <div class="card-footer">
                <div class="row justify-content-end">
                  <div class="col-auto d-flex align-items-center">
                    <span class="material-icons icon-16pt text-black-50 mr-4pt">access_time</span>
                    <p class="flex text-black-50 lh-1 mb-0">
                      <span>{{ formatTime(skill.created_at) }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-skeleton-wrapper>

      <pagination
        v-if="totalSkills > skills.length"
        v-model="currentPage"
        :total-rows="totalSkills"
        :per-page="perPage"
        @change="onPageChange"
      />
    </div>
    <skill-privacy-modal
      :show-modal="showPrivacyModal"
      :is-update="isUpdate"
      :update-id="updateId"
      :access="skillAccess"
      @close="hideSkillModal"
      @fetch="fetchSkills"
    />
  </div>
  <!-- </div> -->
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import SkillPrivacyModal from './SkillPrivacyModal.vue';
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { formatTime } from '../../../common/utils';
import { APPROVAL_STATUS, SKILL_PRIVACY_OPTIONS } from '../../../common/constants';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: {
    Pagination,
    SkillPrivacyModal,
    PageSeparator,
  },
  extends: Page,

  data() {
    return {
      title: 'Manage Skills',

      totalSkills: 0,
      stdSkills: [],
      skills: [],
      isSaving: false,
      isLoading: false,
      perPage: 16,
      areStdSkillsLoaidng: false,
      areSkillTitlesLoaidng: false,
      skillOptions: [{ value: null, text: 'All Skills' }],
      showPrivacyModal: false,
      filters: {
        title: null,
      },
      APPROVAL_STATUS,
      windowWidth: window.innerWidth,
      updateId: null,
      isUpdate: false,
      skillAccess: null,
      SKILL_PRIVACY_OPTIONS,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Skills', active: true },
      ];
    },

    isMobileScreen() {
      return this.windowWidth <= 420;
    },
  },

  methods: {
    ...mapActions('skills', ['getStudentSkills', 'getSkills', 'getSkillTitle', 'deleteSkill', 'getSkillVideo']),
    formatTime,
    openSkillModal() {
      this.showPrivacyModal = true;
    },
    updateSkillPrivacy(id, access) {
      this.showPrivacyModal = true;
      this.updateId = id;
      this.isUpdate = true;
      this.skillAccess = access;
    },
    hideSkillModal() {
      this.showPrivacyModal = false;
      this.updateId = null;
      this.isUpdate = false;
      this.skillAccess = null;
    },
    async fetchSkills(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();
      try {
        const response = await this.getStudentSkills({
          limit: this.perPage,
          offset: (pageNum - 1) * this.perPage,
          ...(this.ordering && { ordering: this.ordering }),
          ...this.filters,
          ...params,
        });
        this.skills = response.data.results;
        this.currentPage = pageNum;
        this.totalSkills = response.data.count;
        this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
        this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    async removeSkill(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to remove the skill.`, {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        });
        if (isConfirmed) {
          await this.deleteSkill(id);
          const refreshPage = this.skills.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchSkills(refreshPage || 1);
          this.makeToast({ variant: 'success', msg: 'Skill Successfully Removed' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    showSkillDetails(id) {
      this.$router.push({ name: 'student-skill-details', params: { id } });
    },
    onFilterApplied() {
      this.fetchSkills();
    },
    async addSkill() {
      this.isSaving = true;
      try {
        const resp = await this.getSkillVideo();
        window.location.href = resp.data.skill_video_ask_url;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isSaving = false;
    },
    async fetchSkillTitle() {
      this.areSkillTitlesLoaidng = true;
      try {
        const resp = (await this.getSkillTitle()).data;
        if (resp) {
          this.skillOptions = [
            { value: null, text: 'All Skills' },
            ...resp.map((skill) => ({ value: skill.title, text: skill.title })),
          ];
        }
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areSkillTitlesLoaidng = false;
    },

    onPageChange(pageNum) {
      this.fetchSkills(pageNum);
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.fetchSkills();
    this.fetchSkillTitle();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
