<template>
  <div :class="containerClass">
    <page-header
      title="My Profile"
      :container-class="null"
      class="mb-32pt"
      button-label="Show Public View"
      :button-path="{ name: 'std-profile-public-view' }"
      button-variant="secondary"
      infoText="View and edit your personal profile."
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <!-- About -->

      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h5 class="my-0">About You</h5>

            <b-skeleton-wrapper :loading="isLoading">
              <template #loading>
                <b-skeleton type="button" height="5px" class="mr-2 mt-2"></b-skeleton>
              </template>
              <router-link
                :to="{ name: 'manage-intro-video' }"
                class="text-primary text-underline"
                v-if="!student.intro_video_url"
              >
                Add Intro Video
              </router-link>
            </b-skeleton-wrapper>
          </div>
        </div>
        <div class="card-body">
          <b-form-group
            :label="$t('userMsgs.avatar')"
            description="This will be your profile photo. Ensure there is good lighting and that no other people or objects are in the photo."
            label-for="avatar"
            label-class="form-label"
            class="row-align-items-center"
          >
            <b-media class="align-items-center" vertical-align="center">
              <user-avatar
                slot="aside"
                size="lg"
                :isLoading="isUploading.avatar"
                :user="user"
                url-field="avatarUrl"
                first-name-field="firstName"
                last-name-field="lastName"
                variant="dark"
              >
              </user-avatar>

              <image-uploader
                @image="e => setImage(e, 'avatar', 'images', 'avatarUrl', ['image'])"
                :isUploading="isUploading.avatar"
                :isRequired="false"
                placeholder="Select professional looking headshot photo (max size: 4MB)."
              />
            </b-media>
          </b-form-group>

          <div class="row">
            <b-form-group
              :label="$t('userMsgs.firstName')"
              label-for="firstname"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input id="firstname" :placeholder="$t('userMsgs.firstName')" v-model="user.firstName" required />
            </b-form-group>

            <b-form-group
              :label="$t('userMsgs.lastName')"
              label-for="lastname"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input id="lastname" :placeholder="$t('userMsgs.lastName')" v-model="user.lastName" required />
            </b-form-group>
          </div>

          <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
            <b-form-input
              id="email"
              :placeholder="$t('authMsgs.emailAddr')"
              :value="user.email"
              type="email"
              autocomplete="off"
              disabled
            />
          </b-form-group>

          <b-form-group :label="$t('studentMsgs.phone')" label-for="phone" label-class="form-label">
            <b-form-input
              id="phone"
              placeholder="(888) 689 - 1235"
              v-model="user.phone"
              v-mask="'(###) ### - ####'"
              :state="errorStates.phone"
              required
            />
            <b-form-invalid-feedback>
              Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Gender" label-for="gender" label-class="form-label">
            <v-select
              id="gender"
              class="form-control v-select-custom"
              label="text"
              v-model="user.gender"
              :reduce="item => item.value"
              placeholder="Select Your Gender"
              :options="genderOptions"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!user.gender" v-bind="attributes" v-on="events" />
              </template>
            </v-select>
          </b-form-group>
          <b-row>
            <b-form-group class="col-md-6">
              <label for="start_date" class="form-label">Start Date at {{ school.name }}</label>
              <date-picker
                v-model="student.start_date"
                type="date"
                format="MM/DD/YYYY"
                value-type="YYYY-MM-DD"
                style="width: 100%"
                lang="en"
                placeholder="MM/DD/YYYY"
                id="start_date"
              ></date-picker>
            </b-form-group>
            <b-form-group class="col-md-6">
              <label for="gradDate" class="form-label"
                >Graduation Date
                <span class="ml-2">
                  <router-link
                    v-if="verificationStatus === VERIFICATION_TESTIMONIAL_STATUS.NO_TESTIMONIAL"
                    :to="{ name: 'manage-student-testimonial' }"
                    class="text-primary"
                    style="text-decoration: underline"
                    >Write a testimonial</router-link
                  >
                </span></label
              >
              <date-picker
                v-model="student.grad_date"
                type="month"
                format="MMMM, YYYY"
                value-type="date"
                style="width: 100%"
                id="grad_date"
                lang="en"
                placeholder="No Date Selected"
              ></date-picker>
            </b-form-group>
          </b-row>
          <b-form-group
            label="Do you have a High School Diploma/ GED?*"
            label-for="ged_or_diploma"
            label-class="form-label"
          >
            <b-form-radio-group
              id="ged_or_diploma"
              v-model="student.ged_or_diploma"
              :options="YES_NO_OPTIONS"
              required
            ></b-form-radio-group>
            <!-- :state="!$v.student.ged_or_diploma.required && $v.student.ged_or_diploma.$dirty ? false : null" -->
            <!-- <b-form-invalid-feedback
              :state="!$v.student.ged_or_diploma.required && $v.student.ged_or_diploma.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            > -->
          </b-form-group>

          <b-form-group :label="`Graduation Year`" label-for="gradDate" label-class="form-label">
            <date-picker
              v-model="student.diploma_date"
              type="year"
              format="YYYY"
              style="width: 100%"
              value-type="date"
              id="diploma_date"
              lang="en"
              placeholder="No Date Selected"
            ></date-picker>
          </b-form-group>
          <b-form-group :label="$t('studentMsgs.cnaLicenseNo')" label-for="cnaLicenseNo" label-class="form-label">
            <b-form-input
              id="cnaLicenseNo"
              placeholder="CNXXXXXXXXXX"
              v-model="student.cna_license_no"
              v-mask="'CN##########'"
              :state="errorStates.cnaLicenseNo"
            />
            <b-form-invalid-feedback
              >Invalid number. Please make sure that it's in correct format i.e. CNXXXXXXXXXX.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group label="CMA License Number" label-for="cmaLicenseNo" label-class="form-label">
            <b-form-input id="cmaLicenseNo" placeholder="Enter CMA License Number" v-model="student.cma_license_no" />
          </b-form-group>

          <b-form-group :label="$t('studentMsgs.quote')" label-for="quote" label-class="form-label">
            <b-form-input
              id="quote"
              :placeholder="$t('studentMsgs.quotePlaceholder')"
              v-model="student.quote"
              maxlength="70"
            />
          </b-form-group>

          <b-form-group :label="$t('userMsgs.bio')" label-for="about" label-class="form-label">
            <b-form-textarea
              id="about"
              :placeholder="`${$t('studentMsgs.bioPlaceholder')}`"
              :rows="5"
              v-model="user.bio"
            />
          </b-form-group>
        </div>
      </div>

      <!-- <page-separator title="Address" /> -->

      <div class="card">
        <div class="card-header">
          <h5 class="my-0">Address</h5>
        </div>
        <div class="card-body">
          <h6>Your address won't be shown to employers. We use your address to match you up with jobs in your area.</h6>
          <!-- Address Fields -->

          <b-form-group label="Street Address" label-for="address" label-class="form-label">
            <b-form-input id="address" placeholder="Street Address" v-model="student.address" required />
          </b-form-group>

          <div class="row">
            <b-form-group
              :label="$t('addressMsgs.zipcode')"
              label-for="zipcode"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input id="zipcode" :placeholder="$t('addressMsgs.zipcode')" v-model="student.zipcode" required />
            </b-form-group>

            <b-form-group :label="$t('addressMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
              <b-form-input id="city" :placeholder="$t('addressMsgs.city')" v-model="student.city" required />
            </b-form-group>
          </div>

          <div class="row">
            <b-form-group :label="$t('addressMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
              <v-select
                id="state"
                class="form-control v-select-custom"
                label="text"
                v-model="student.state"
                :reduce="state => state.value"
                placeholder="Select State"
                :options="stateOptions"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!student.state" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
            </b-form-group>

            <b-form-group
              :label="$t('addressMsgs.country')"
              label-for="country"
              label-class="form-label"
              class="col-md-6"
            >
              <v-select
                id="country"
                class="form-control v-select-custom"
                label="text"
                disabled
                v-model="student.country"
                :reduce="country => country.value"
                :placeholder="$t('addressMsgs.countryPlaceholder')"
                :options="countryOptions"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!student.country" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
            </b-form-group>
          </div>
        </div>
      </div>

      <!-- <page-separator title="Experience" /> -->

      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h5 class="my-0">Experience</h5>
          <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-skeleton type="button" height="5px" class="mr-2 mt-2"></b-skeleton>
            </template>
            <router-link :to="{ name: 'std-manage-resume' }" class="text-primary text-underline">
              Build Resume
            </router-link>
          </b-skeleton-wrapper>
        </div>
        <div class="card-body">
          <b-form-group label="Resume" label-for="resume" label-class="form-label" class="row-align-items-center">
            <b-progress :max="100" animated v-if="isUploading.resume">
              <b-progress-bar :value="uploadPercent.resume">
                <span>
                  Uploading (<strong>{{ uploadPercent.resume }} %</strong>)
                </span>
              </b-progress-bar>
            </b-progress>

            <span v-else-if="student.resume_url">
              <div class="row">
                <div class="col-md-10">
                  <a
                    href="#"
                    @click.prevent="
                      downloadGivenFile({
                        fileUrl: student.resume_url,
                        removeTimestamp: true
                      })
                    "
                    v-b-popover.hover.top="'Download'"
                    ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(student.resume_url, true) }}
                  </a>
                </div>
                <div class="text-right col-md-2">
                  <a href="#" class="text-danger" @click.prevent="removeFile('resume', 'resume_url')"
                    ><i class="fas fa-times-circle"></i> Remove
                  </a>
                </div>
              </div>
            </span>
            <b-media class="align-items-center" vertical-align="center" v-else>
              <b-form-file
                id="resume"
                placeholder="Select Resume File (max size: 10MB, only .pdf, .docx and .doc files allowed)"
                :browse-text="$t('generalMsgs.browse')"
                v-model="selectedUploadFile.resume"
                @input="uploadFile('resume', 'documents', 'resume_url', ['application'])"
                :disabled="isUploading.resume"
                accept=".pdf, .docx, .doc"
              />
            </b-media>
          </b-form-group>

          <!-- Experience Related Fields (Replaced by Resume Builder, use only if needed) -->

          <!-- <b-form-group label="Work Experience" label-for="workExp" label-class="form-label">
            <v-select
              id="workExp"
              class="form-control v-select-custom"
              label="text"
              v-model="student.work_experience"
              :reduce="(exp) => exp.value"
              placeholder="How many years of caregiver experience do you have?"
              :options="workExpOptions"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!student.work_experience" v-bind="attributes" v-on="events" />
              </template>
            </v-select>
          </b-form-group>

          <b-form-group label="Last Employer" label-for="curr_employer" label-class="form-label">
            <b-form-input
              id="curr_employer"
              placeholder="Enter last employer's name (if any)."
              v-model="student.curr_employer"
            />
          </b-form-group> -->
        </div>
      </div>

      <!-- Student Profile Info -->
      <div class="card">
        <div class="card-header">
          <h5 class="my-0">Preferences</h5>
        </div>
        <div class="card-body">
          <!-- Prefereneces / Work Schedule Fields -->
          <b-form-group
            label="Desired Work Type"
            label-for="desired_work_type"
            label-class="form-label"
            style="height: auto"
          >
            <v-select
              id="desired_work_type"
              class="form-control v-select-custom"
              label="text"
              v-model="student.desired_work_type"
              :reduce="item => item.value"
              placeholder="Select the type(s) of work you are looking for"
              :options="workTypeOptions"
              multiple
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!get(student.desired_work_type, 'length')"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </b-form-group>

          <b-form-group label="Desired Work Time" label-for="desired_work_time" label-class="form-label">
            <v-select
              id="desired_work_time"
              class="form-control v-select-custom"
              label="text"
              v-model="student.desired_work_time"
              :reduce="item => item.value"
              placeholder="Select the time(s) would you be able to work."
              :options="workTimeOptions"
              multiple
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!get(student.desired_work_time, 'length')"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </b-form-group>

          <b-form-group label="Desired Work Days" label-for="desired_work_day" label-class="form-label">
            <v-select
              id="desired_work_day"
              class="form-control v-select-custom"
              label="text"
              v-model="student.desired_work_day"
              :reduce="item => item.value"
              placeholder="Select the days(s) you would be able to work."
              :options="workDayOptions"
              multiple
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!get(student.desired_work_day, 'length')"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </b-form-group>

          <b-form-group label="Preferable Work Travel" label-for="work_travel" label-class="form-label">
            <v-select
              id="work_travel"
              class="form-control v-select-custom"
              label="text"
              v-model="student.work_travel"
              :reduce="item => item.value"
              placeholder="How much are you willing to travel for work?"
              :options="workTravelOptions"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!student.work_travel" v-bind="attributes" v-on="events" />
              </template>
            </v-select>
          </b-form-group>

          <b-form-group
            label="Interested Facility Type(s)"
            label-for="interested_facility_types"
            label-class="form-label"
          >
            <v-select
              id="interested_facility_types"
              class="form-control v-select-custom"
              label="text"
              v-model="student.interested_facility_types"
              :reduce="item => item.value"
              placeholder="Select the facility types you are interested in working at"
              :options="facilityTypeOptions"
              multiple
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!get(student.interested_facility_types, 'length')"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </b-form-group>

          <b-form-group label="Career Preference(s)" label-for="career_preferences" label-class="form-label">
            <v-select
              id="career_preferences"
              class="form-control v-select-custom"
              label="name"
              v-model="student.career_preferences"
              :reduce="item => item.id"
              placeholder="Select the careers you prefer"
              :options="careerPreferences"
              :loading="areCareerPrefsLoading"
              multiple
            >
              <template slot="option" slot-scope="option">
                <div class="d-flex align-items-center">
                  <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                    <b-img :src="option.photo_url" class="img-fluid" width="20" alt="Logo" v-if="option.photo_url" />
                    <md-icon class="text-dark" v-else>local_hospital</md-icon>
                  </fmv-avatar>
                  <i>{{ option.name }}</i
                  ><span class="ml-1">- (${{ $n(option.salary_range_from) }} - ${{ $n(option.salary_range_to) }})</span
                  ><span class="ml-1">- {{ option.description }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>

          <b-form-group label="Language Preference(s)" label-for="lang_preferences" label-class="form-label">
            <v-select
              id="lang_preferences"
              class="form-control v-select-custom"
              label="name"
              v-model="student.lang_preferences"
              :reduce="item => item.value"
              placeholder="Select Your Preferred Language(s)"
              :options="langOptions"
              multiple
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!get(student.lang_preferences, 'length')"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </b-form-group>
        </div>
      </div>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Update</span>
      </b-btn>
    </b-form>
    <add-pii-modal
      :show-modal="showDocModal"
      :title="addDocTitle"
      :type="addDocType"
      @add="piiDocAdded"
      @close="hideModal"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { countries } from 'countries-list';
import { get, map, pick } from 'lodash';
import { mapActions } from 'vuex';
import moment from 'moment';
import AddPiiModal from '../../SuperAdminPortal/Students/AddPiiModal.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/en';
import Page from '../../../components/Page.vue';
import {
  CNA_LICENSE_NUM_REGEX,
  PHONE_FORMAT_REGEX,
  VERIFICATION_TESTIMONIAL_STATUS,
  PII_TYPES,
  PII_CONFIG_TYPE
} from '../../../common/constants';

import PageHeader from '../../../components/Ui/PageHeader.vue';
import {
  parseFileNameFromUrl,
  getLangOptions,
  formatToAPIDate,
  formatDateSimple,
  isDateExpired as isPIIExpired
} from '../../../common/utils';
import UserAvatar from '../../../components/User/UserAvatar.vue';
var UsaStates = require('usa-states').UsaStates;
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: { PageHeader, UserAvatar, DatePicker, AddPiiModal, ImageUploader },
  extends: Page,

  data() {
    return {
      title: 'Manage Profile',
      PII_TYPES,
      PII_CONFIG_TYPE,
      showDocModal: false,
      addDocType: '',
      addDocTitle: '',
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        bio: '',
        avatarUrl: '',
        gender: ''
      },
      student: {
        intro_video_url: '',
        grad_date: null,
        cna_license_no: '',
        work_experience: '',
        curr_employer: '',
        quote: '',
        start_date: null,
        cma_license_no: '',
        desired_work_type: [],
        desired_work_time: [],
        desired_work_day: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
        work_travel: '',
        interested_facility_types: [],
        career_preferences: [],
        lang_preferences: [],
        diploma_date: '',
        ged_or_diploma: null,

        resume_url: '',

        bls_url: '',
        vehicle_url: '',

        first_aid_url: '',

        address: '',
        zipcode: '',
        city: '',
        state: '',
        country: 'United States'
      },
      school: { name: '' },
      piiTypes: {
        tb_test: '',
        covid_vacc: '',
        social_sec_card: '',
        state_id: '',
        cpr: ''
      },
      expiresAt: { tb_test: null, covid_vacc: null, social_sec_card: null, state_id: null, cpr: null },
      countryOptions: [],
      stateOptions: [],
      workExpOptions: [
        { value: '0-1', text: '0-1 year' },
        { value: '1-2', text: '1-2 years' },
        { value: '3-4', text: '3-4 years' },
        { value: '5-6', text: '5-6 years' },
        { value: '7+', text: '7+ years' }
      ],
      workDayOptions: [],
      workTypeOptions: [
        { value: 'part_time', text: 'Part-time' },
        { value: 'full_time', text: 'Full-time' },
        { value: 'not_sure', text: 'Not sure' }
      ],
      workTimeOptions: [
        { value: 'day', text: 'Day' },
        { value: 'evening', text: 'Evening' },
        { value: 'overnight', text: 'Overnight' }
      ],
      workTravelOptions: [],
      facilityTypeOptions: [
        { value: 'adult_day_care', text: 'Adult day care' },
        { value: 'assisted_living', text: 'Assisted living' },
        { value: 'hospital', text: 'Hospital' },
        { value: 'hospice_care', text: 'Hospice care' },
        { value: 'home_care', text: 'Home care' },
        { value: 'longterm_care', text: 'Long-term care' },
        { value: 'other', text: 'Other' }
      ],
      careerPreferences: [],
      langOptions: [],
      genderOptions: [
        { value: 'male', text: 'Male' },
        { value: 'female', text: 'Female' },
        { value: 'other', text: 'Other' }
      ],

      errorStates: {
        userEmail: null,
        cnaLicenseNo: null,
        //  cmaLicenseNo: null,
        phone: null
      },
      passErrorCode: null,

      isPassCopied: false,
      isLoading: false,
      areSchoolsLoading: false,
      isLogoUploading: false,
      areCareerPrefsLoading: false,

      isUploading: {
        avatar: false,
        resume: false,
        cpr: false,
        bls: false,
        vehicle: false,
        tb_test: false,
        firstAid: false,
        covid_vacc: false,
        state_id: false,
        social_sec_card: false
      },
      selectedUploadFile: {
        state_id: null,
        avatar: null,
        resume: null,
        cpr: null,
        bls: null,
        vehicle: null,
        tb_test: null,
        firstAid: null,
        covid_vacc: null,
        social_sec_card: null
      },
      uploadPercent: {
        avatar: 0,
        resume: 0,
        cpr: 0,
        bls: 0,
        vehicle: 0,
        tb_test: 0,
        firstAid: 0,
        covid_vacc: 0,
        state_id: 0,
        social_sec_card: 0
      },
      uploadCancelTokenSource: {
        avatar: null,
        resume: null,
        cpr: null,
        bls: null,
        vehicle: null,
        tb_test: null,
        firstAid: null,
        covid_vacc: null,
        state_id: null,
        social_sec_card: null
      },

      fileSizeExceed: { tb_test: false, covid_vacc: false, state_id: false, social_sec_card: false, cpr: false },
      invalidFileType: { tb_test: false, covid_vacc: false, state_id: false, social_sec_card: false, cpr: false },
      VERIFICATION_TESTIMONIAL_STATUS,
      verificationStatus: '',
      student_piis: [],

      piiTypesConfig: {},
      YES_NO_OPTIONS: [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' }
      ]
    };
  },

  validations() {
    return {};
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage Profile', active: true }
      ];
    },
    isFormLoading() {
      return (
        this.isLoading ||
        this.isLogoUploading ||
        this.areSchoolsLoading ||
        this.areCareerPrefsLoading ||
        Object.values(this.isUploading).some(val => val)
      );
    }
  },

  methods: {
    ...mapActions('student', ['getMyStudentProfile', 'updateMyStudentProfile', 'getStudentUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('careerPreference', ['getAllCareerPreferences']),
    ...mapActions('testimonial', ['verifyTestimonial']),
    parseFileNameFromUrl,
    formatDateSimple,
    get,
    isPIIExpired,
    setImage(file, fileType, uploadType = 'images', profileUrlField = 'resume_url', allowedTypes = ['image']) {
      this.selectedUploadFile[fileType] = file;
      this.uploadFile(fileType, uploadType, profileUrlField, allowedTypes);
    },

    openAddModal(docType, title) {
      this.showDocModal = true;
      this.addDocType = docType;
      this.addDocTitle = title;
    },
    piiDocAdded(file, url, type, date) {
      this.showDocModal = false;
      this.documentAdded = true;
      this.selectedUploadFile[type] = file;
      this.piiTypes[type] = url;
      this.expiresAt[type] = date;
      this.addDocType = '';
      this.addDocTitle = '';
    },
    hideModal() {
      this.showDocModal = false;
      this.addDocType = '';
      this.addDocTitle = '';
    },

    async removePiiFile(fileType) {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        'Please confirm that you want to remove the selected document. You will need to reupload the document if required.',
        {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }
      );
      if (isConfirmed) {
        this.selectedUploadFile[fileType] = null;
        this.piiTypes[fileType] = null;
        this.expiresAt[fileType] = null;
      }
    },

    async verification() {
      try {
        const res = await this.verifyTestimonial();
        this.verificationStatus = res.data.status;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async onSubmit() {
      this.isLoading = true;

      this.errorStates.userEmail = this.errorStates.cnaLicenseNo = this.errorStates.phone = null;

      this.$v.$touch();

      if (this.user.phone && !PHONE_FORMAT_REGEX.test(this.user.phone)) {
        this.errorStates.phone = false;
        this.makeToast({ variant: 'danger', msg: 'Please correct the phone number format.' });
      } else if (this.student.cna_license_no && !CNA_LICENSE_NUM_REGEX.test(this.student.cna_license_no)) {
        this.errorStates.cnaLicenseNo = false;
        this.makeToast({ variant: 'danger', msg: 'Please correct the CNA license number format.' });
      } else if (this.$v.$invalid) {
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      } else {
        try {
          // this.student.grad_date = this.student.grad_date ? formatToAPIDate(moment(this.student.grad_date)) : null;
          this.student.start_date = this.student.start_date ? formatToAPIDate(moment(this.student.start_date)) : null;
          let piiArr = [];
          for (const item in this.piiTypes) {
            if (this.piiTypes[item]) {
              piiArr.push({
                type: item,
                document_url: this.piiTypes[item],
                expire_at: this.expiresAt[item] ? formatToAPIDate(this.expiresAt[item]) : null
              });
            }
          }
          await this.updateMyStudentProfile({
            data: {
              ...this.student,
              grad_date: this.student.grad_date ? formatToAPIDate(moment(this.student.grad_date)) : null,
              diploma_date: this.student.diploma_date ? formatToAPIDate(moment(this.student.diploma_date)) : null,
              ged_or_diploma: this.student.ged_or_diploma,
              student_piis: piiArr,
              user: {
                first_name: this.user.firstName,
                last_name: this.user.lastName,
                phone: this.user.phone,
                bio: this.user.bio,
                avatar_url: this.user.avatarUrl,
                gender: this.user.gender
              }
            }
          });
          this.makeToast({ variant: 'success', msg: 'Profile Successfully Updated' });
        } catch (err) {
          if (get(err, 'response.data.user')) {
            this.errorStates.userEmail = false;
          }
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }

      this.isLoading = false;
    },

    async uploadFile(fileType, uploadType = 'images', profileUrlField = 'resume_url', allowedTypes = ['image']) {
      this.fileSizeExceed[fileType] = this.invalidFileType[fileType] = false;

      if (this.selectedUploadFile[fileType].size > 10 * 1024 * 1024) {
        this.fileSizeExceed[fileType] = true;
        return;
      } else if (allowedTypes.every(val => !this.selectedUploadFile[fileType].type.includes(val))) {
        this.invalidFileType[fileType] = true;
        return;
      }

      this.isUploading[fileType] = true;
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();

      try {
        const urlResp = await this.getStudentUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type,
          upload_type: uploadType
        });

        if (fileType === 'avatar') this.user.avatarUrl = urlResp.upload_url.split('?')[0];
        else if (PII_TYPES.includes(fileType)) {
          this.piiTypes[fileType] = urlResp.upload_url.split('?')[0];
        } else this.student[profileUrlField] = urlResp.upload_url.split('?')[0];
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token
          }
        });
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
        if (fileType === 'avatar') this.user.avatar_url = null;
        else if (PII_TYPES.includes(fileType)) {
          this.piiTypes[fileType] = null;
        } else this.student[profileUrlField] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
    },

    async downloadGivenFile({ fileUrl, removeTimestamp = true }) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left'
      });

      await this.downloadFile({ fileUrl, removeTimestamp });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left'
      });
    },

    removeFile(fileType, profileUrlField = 'resume_url') {
      this.selectedUploadFile[fileType] = null;
      if (PII_TYPES.includes(profileUrlField)) {
        this.piiTypes[fileType] = null;
      } else this.student[profileUrlField] = null;
    },

    fillInOptions() {
      var usStates = new UsaStates();
      this.stateOptions = this.stateOptions.concat(
        map(usStates.states, state => ({
          value: state.name,
          text: state.name
        }))
      );
      this.countryOptions = this.countryOptions.concat(
        map(countries, country => ({
          value: country.name,
          text: country.name
        }))
      );

      this.workDayOptions = map(moment.weekdays(), day => ({ value: day.toLowerCase(), text: day }));

      this.workTravelOptions = map([...Array(9).keys()], num => ({
        value: `${(num + 1) * 10}`,
        text: `${(num + 1) * 10} miles`
      }));
      this.workTravelOptions.push({ value: '100+', text: '100+ miles' });

      this.langOptions = getLangOptions();
    },

    async fetchStudentProfile() {
      this.isLoading = true;
      const resp = await this.getMyStudentProfile(true);

      this.student = {
        ...pick(resp, [
          'cna_license_no',
          'work_experience',
          'curr_employer',
          'quote',
          'desired_work_type',
          'desired_work_time',
          'desired_work_day',
          'work_travel',
          'interested_facility_types',
          'career_preferences',
          'lang_preferences',
          'resume_url',
          'cpr_url',
          'bls_url',
          'vehicle_url',
          'tb_url',
          'first_aid_url',
          'covid_url',
          'address',
          'zipcode',
          'city',
          'state',
          'country',
          'cma_license_no',
          'start_date',
          'intro_video_url'
        ]),
        grad_date: resp.grad_date ? new Date(resp.grad_date) : null,
        diploma_date: resp.diploma_date ? new Date(resp.diploma_date) : null,
        ged_or_diploma: resp.ged_or_diploma
      };
      this.student_piis = resp.student_piis;

      this.student_piis.forEach(pii => {
        this.piiTypes[pii.type] = pii.document_url;
        this.expiresAt[pii.type] = pii.expire_at;
      });
      this.user.firstName = resp.user.first_name;
      this.user.lastName = resp.user.last_name;
      this.user.email = resp.user.email;
      this.user.phone = resp.user.phone;
      this.user.bio = resp.user.bio;
      this.user.avatarUrl = resp.user.avatar_url;
      this.user.gender = resp.user.gender;
      this.school = resp.school;
      this.isLoading = false;

      if (this.$route.query.scrollTo) {
        document.getElementById('piiCard').scrollIntoView({ behavior: 'smooth' });
      }
    },

    async fetchCareerPreferences() {
      this.areCareerPrefsLoading = true;

      const response = await this.getAllCareerPreferences({});
      this.careerPreferences = response.data;

      this.areCareerPrefsLoading = false;
    }
  },

  async mounted() {
    this.fillInOptions();
    this.verification();
    this.fetchStudentProfile();

    this.fetchCareerPreferences();
  }
};
</script>
