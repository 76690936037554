<template>
  <div :class="containerClass">
    <page-header
      title="Intro Video"
      :container-class="null"
      class="mb-32pt"
      infoText="Upload your introduction video."
    />

    <page-separator title="" />
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="d-flex justify-content-center">
          <div class="card card-block card-stretch card-height col-md-12">
            <div class="card-body text-center">
              <i class="fas fa-photo-video fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />

              <p><b-skeleton width="60%"></b-skeleton></p>
              <p><b-skeleton width="90%"></b-skeleton></p>
              <p><b-skeleton width="75%"></b-skeleton></p>
              <div class="d-flex justify-content-center">
                <b-skeleton type="button" width="250px"></b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        class="d-flex justify-content-center"
        v-if="isUploadingVideo && $route.query.success == 1 && !uploaded_video_url"
      >
        <div class="card card-block card-stretch card-height col-md-12">
          <div class="card-body text-center">
            <i class="fas fa-circle-notch fa-spin fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
            <h4>Video Uploading...</h4>
            <p class="text-muted">
              Feel free to leave this page. Your video will remain uploading in the background and will show up here
              when finished
            </p>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center" v-else-if="!intro_video_url">
        <div class="card card-block card-stretch card-height col-md-12">
          <div class="card-body text-center">
            <i class="fas fa-photo-video fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
            <h4>No Intro Video Found</h4>
            <p class="text-muted">Click below to create your introduction video.</p>
            <b-btn
              variant="primary"
              style="width: 150px"
              @click.prevent="saveVideo"
              class="btn-normal"
              :disabled="isSaving || isLoading"
            >
              <i v-if="isSaving" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>Create Intro</span>
            </b-btn>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center" v-else>
        <div class="card card-block card-stretch card-height col-md-12">
          <div class="card-body">
            <!-- <h5>Primary</h5>
            <p class="mb-1">Your primary billing method is used for all recurring payments.</p> -->

            <page-separator title="" />
            <div class="d-flex align-items-center">
              <video style="width: 100%; margin: auto" id="video-preview" controls :src="intro_video_url" />
            </div>
            <!-- <p class="text-muted mt-1">
              Note: To change the default payment method, remove the existing method and add a new one.
            </p> -->

            <div class="d-flex justify-content-end mt-3">
              <b-btn
                variant="danger"
                @click.prevent="removeVideo"
                class="btn-normal"
                :disabled="isRemoving"
                style="width: 120px"
              >
                <i v-if="isRemoving" class="fas fa-circle-notch fa-spin"></i>
                <span v-else>Remove</span>
              </b-btn>
            </div>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
//import { get } from 'lodash';
export default {
  name: 'ManageIntroVideoPage',
  components: {
    PageSeparator,
    PageHeader,
  },
  extends: Page,
  data() {
    return {
      title: 'My Introduction Video',
      intro_video_url: null,
      isLoading: false,
      isSaving: false,
      isRemoving: false,
      isUploadingVideo: false,
      uploaded_video_url: null,
    };
  },
  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage Introduction Video', active: true },
      ];
    },
  },
  methods: {
    ...mapActions('student', ['getMyStudentProfile', 'getVideoUrl', 'updateIntroVideoUrl']),
    async fetchStudentProfile() {
      this.isLoading = true;
      const resp = await this.getMyStudentProfile(true);

      this.intro_video_url = resp.intro_video_url;
      this.isLoading = false;
    },
    async removeVideo() {
      this.isRemoving = true;
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        'Please confirm that you want to remove your introduction video.',
        {
          title: 'Remove Video',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        try {
          await this.updateIntroVideoUrl();
          this.intro_video_url = null;
          this.makeToast({ variant: 'success', msg: 'Intro Video Removed!' });
          this.fetchStudentProfile();
        } catch (_err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }
      this.isRemoving = false;
    },
    async saveVideo() {
      this.isSaving = true;
      try {
        const resp = (await this.getVideoUrl()).data;
        window.location.href = resp.video_ask_url;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        this.isSaving = false;
      }
    },
  },
  async mounted() {
    // this.isUploadingVideo = true;
    this.fetchStudentProfile();

    if (!this.intro_video_url && this.$route.query.success == 1) {
      this.isUploadingVideo = true;
      const resp = await this.getMyStudentProfile(true);
      this.uploaded_video_url = resp.intro_video_url;
      this.intro_video_url = resp.intro_video_url;
      if (this.intro_video_url || this.uploaded_video_url) {
        this.$router.replace({
          name: 'manage-intro-video',
        });
      }
    }
  },
};
</script>

<style>
</style>
