<template>
  <div class="page-section">
    <div :class="containerClass">
      <h2 class="text-center mb-2">My Info</h2>
      <p class="text-center mb-4">
        View and manage your Questions, Skills, Documents, Payments, Resume and Sponsorship Applications on my info.
      </p>
      <div class="row mb-5">
        <template v-for="info in myInfo">
          <DashboardCard :key="info.id" :guide="info" color="primary" class="col-md-6 mb-2" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import DashboardCard from '@/components/DashboardCard.vue';
import Page from '@/components/Page.vue';

export default {
  extends: Page,
  components: { DashboardCard },
  data() {
    return {
      title: 'My Info',
      windowWidth: window.innerWidth
    };
  },

  computed: {
    ...mapGetters('school', ['getInAppPaymentStatus', 'getPaymentStatus']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Setup Guide', active: true }
      ];
    },
    isMobileScreen() {
      return this.windowWidth <= 600;
    },
    myInfo() {
      return [
        {
          id: 1,
          title: 'My Uploads',
          description: 'View and manage your uploaded documents and credentials.',
          abbr: 'uploaded_docs',
          redirectTo: 'std-manage-records',
          icon: 'drive_folder_upload',
          btnText: 'Complete'
        },
        {
          id: 2,
          title: 'My Payments',
          description: `Track and organize your payment history.`,
          abbr: 'payments_paid',
          redirectTo: 'std-payment-list',
          icon: 'payment',
          btnText: 'Complete'
        },
        {
          id: 3,
          title: 'My Resume',
          description: 'View and update your custom resume for employment.',
          abbr: 'resume_build',
          redirectTo: 'std-manage-resume',
          icon: 'description',
          btnText: 'Complete'
        },
        {
          id: 4,
          title: 'My Applications',
          description: 'Monitor the status of your submitted sponsorship applications.',
          redirectTo: 'std-manage-applications',
          icon: 'assignment',
          btnText: 'Search'
        },
        {
          id: 8,
          title: 'My Simulations',
          description: 'Monitor the status of your submitted sponsorship applications.',
          redirectTo: 'student-submissions-listing',
          icon: 'mic',
          btnText: 'Search'
        },
        ...(this.getInAppPaymentStatus
          ? [
              {
                id: 5,
                title: 'My Plans',
                description: `View and manage your upcoming dues`,
                redirectTo: 'manage-plans',
                icon: 'attach_money',
                btnText: 'Find'
              }
            ]
          : []),
        ...(this.getPaymentStatus
          ? [
              {
                id: 6,
                title: 'My Skills',
                description: `View and manage skills for your career profile.`,
                redirectTo: 'my-skills',
                icon: 'videocam',
                btnText: 'Find'
              },
              {
                id: 7,
                title: 'My Questions',
                description: `Review your inquiries and responses for continuous learning.`,
                redirectTo: 'my-questions',
                icon: 'help',
                btnText: 'Find'
              },
              {
                id: 9,
                title: 'My Attendance',
                description: `Review your inquiries and responses for continuous learning.`,
                redirectTo: 'student-attendance',
                icon: 'calendar_today',
                btnText: 'Find'
              }
            ]
          : [])
      ];
    }
  },

  methods: {
    get,
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
