<template>
  <b-popover
    v-if="targetId"
    ref="popover"
    :target="`school-popover-${targetId}`"
    triggers="hover"
    :placement="placement"
    :html="true"
    custom-class="popover-lg"
  >
    <div class="media">
      <div class="media-left mr-12pt">
        <img :src="school.logo_url || DefaultAvatar" width="40" height="40" alt="logo" class="rounded" />
      </div>
      <div class="media-body">
        <div class="card-title mb-0">{{ school.name }}</div>
        <p class="lh-1 mb-0">
          <span class="material-icons icon-16pt text-black-50 mr-1">location_on</span>
          <span class="text-black-50 small font-weight-bold">{{ school.city }}, {{ school.state }}</span>
        </p>
      </div>
    </div>

    <div class="my-16pt">
      <strong>About:</strong>
      <p class="text-black-70">
        <span v-if="school.bio">{{ school.short_bio }}</span>
        <i v-else>{{ school.name }} has not added any bio.</i>
      </p>
    </div>

    <div class="mb-16pt">
      <div class="d-flex align-items-center" v-if="school.website_url">
        <span class="material-icons icon-16pt text-black-50 mr-8pt">link</span>
        <p class="flex text-black-50 lh-1 mb-0">
          <small
            ><a :href="getClickableLink(school.website_url)" target="_blank" class="text-primary text-break">{{
              school.website_url
            }}</a></small
          >
        </p>
      </div>
      <div class="d-flex align-items-center">
        <span class="material-icons icon-16pt text-black-50 mr-8pt">people_outline</span>
        <p class="flex text-black-50 lh-1 mb-0">
          <small>
            <b-skeleton-wrapper :loading="stdCountLoading">
              <template #loading><b-skeleton width="100%"></b-skeleton></template>
              Active Students: {{ activeStdCount }}
            </b-skeleton-wrapper>
          </small>
        </p>
      </div>
    </div>

    <div class="row align-items-center" v-if="getLoggedInUser.role !== USER_ROLES.STUDENT">
      <div class="col text-right">
        <a :href="`mailto:${school.owner.email}`" class="btn btn-primary"
          >Contact School <span class="material-icons icon-16pt ml-1">message</span></a
        >
      </div>
    </div>
  </b-popover>
</template>

<script>
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { mapGetters } from 'vuex';
import { USER_ROLES } from '../../../common/constants';

export default {
  components: {},

  props: {
    school: { required: true },
    targetId: { required: true },
    placement: { type: String, default: 'left' },
    stdCountLoading: { type: Boolean, default: false },
    activeStdCount: { type: Number, default: 0 },
  },

  data() {
    return {
      DefaultAvatar,
      USER_ROLES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Sample Page', active: true },
      ];
    },
  },

  methods: {
    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
    },
  },

  async mounted() {},
};
</script>
