<template>
  <div class="page-section">
    <div :class="containerClass">
      <h2 class="text-center mb-2">Welcome {{ getLoggedInUser.first_name }}!</h2>
      <p class="text-center mb-4">
        Our Student Center is designed to help you be successful. Follow the tasks listed below.
      </p>
      <div class="row mb-5">
        <template v-for="guide in get(getLoggedInUser, 'linked_entity.std_dashboard_config.dashboard_config') || []">
          <DashboardCard
            v-if="guide.enable"
            :key="guide.id"
            :guide="guide"
            :setupStatuses="setupStatuses"
            class="col-md-6 mb-2"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import DashboardCard from '@/components/DashboardCard.vue';
import Page from '../../../components/Page.vue';

export default {
  extends: Page,
  name: 'SchoolSetupGuide',
  components: { DashboardCard },
  data() {
    return {
      title: 'Home',

      isLoading: false,
      setupStatuses: {
        uploaded_docs: true,
        question_asked: true,
        resume_build: true,
        payments_paid: true,
        practice_skills: '',
        jobs: '',
        employers: ''
      },
      status: null,
      windowWidth: window.innerWidth,
      showPrivacyModal: false
    };
  },

  computed: {
    ...mapGetters('navbarConfig', ['getLinkedEntity']),
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getPaymentStatus']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Setup Guide', active: true }
      ];
    },
    isMobileScreen() {
      return this.windowWidth <= 600;
    }
  },

  methods: {
    ...mapActions('student', ['getStudentDashboardStatus']),
    get,
    loadVoiceflow() {
      var stdBotApiKey = this.getLoggedInUser.linked_entity.std_bot_api_key;
      var id = this.getLoggedInUser.id.toString();
      var v = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];

      v.onload = function() {
        window.voiceflow.chat.load({
          verify: { projectID: stdBotApiKey },
          url: 'https://general-runtime.voiceflow.com',
          versionID: 'production',
          userID: id
        });
      };

      v.src = 'https://cdn.voiceflow.com/widget/bundle.mjs';
      v.type = 'text/javascript';
      s.parentNode.insertBefore(v, s);
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    async fetchDashboardStatus() {
      this.isLoading = true;
      try {
        this.setupStatuses = (await this.getStudentDashboardStatus()).data;
      } catch (_err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    }
  },
  watch: {
    getPaymentStatus: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.status = value;
        }
      }
    }
  },
  async mounted() {
    this.loadVoiceflow();
    window.addEventListener('resize', this.handleResize);
    this.fetchDashboardStatus();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
