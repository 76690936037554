<template>
  <div data-perfect-scrollbar>
    <b-skeleton-wrapper :loading="isLoading" v-if="isLoading">
      <template #loading>
        <div class="page-section pb10 student-page">
          <div
            class="container page__container d-flex align-items-center"
            v-if="getLoggedInUser.role !== USER_ROLES.STUDENT"
          >
            <p class="backResult text-50">
              <!-- <router-link v-if="$route.params.backTo" :to="{ name: 'hires' }" style="text-decoration: underline">
                <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Hires
              </router-link>
              <router-link
                v-else-if="$route.params.to === 'app-profile'"
                :to="{ name: 'app-profile' }"
                style="text-decoration: underline"
              >
                <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Application Profile
              </router-link> -->

              <!-- v-else-if="!$route.params.backTo" -->
              <span class="clickable-item" @click.prevent="goBack()" style="text-decoration: underline">
                <span style="font-size: 35px" class="material-icons icon-18pt text-80">navigate_before</span>
              </span>
            </p>
          </div>

          <div
            class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
          >
            <b-col md="2">
              <b-skeleton-img height="113px" no-aspect></b-skeleton-img>
            </b-col>
            <div class="flex mb-32pt mb-md-0 mobilemb0">
              <h4 class="mb-0"><b-skeleton width="60%" height="1.5rem"></b-skeleton></h4>
              <p class="d-flex mt-2 mb-3">
                <b-skeleton width="85%"></b-skeleton>
              </p>

              <b-row>
                <b-col>
                  <b-skeleton type="button" width="200px"></b-skeleton>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

        <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="mb-1">
                    <h5 class="card-title fw600"><b-skeleton width="50%"></b-skeleton></h5>
                  </div>

                  <div class="d-flex">
                    <div class="flex">
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">location_on</i><b-skeleton width="75%"></b-skeleton>
                      </h6>
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">timelapse</i><b-skeleton width="50%"></b-skeleton>
                      </h6>
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">access_time</i><b-skeleton width="50%"></b-skeleton>
                      </h6>
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">event</i><b-skeleton width="50%"></b-skeleton>
                      </h6>
                    </div>
                  </div>

                  <div></div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="mb-1">
                    <h5 class="card-title fw600"><b-skeleton width="50%"></b-skeleton></h5>
                  </div>

                  <h6>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="60%"></b-skeleton>
                  </h6>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-skeleton-wrapper>

    <div v-else>
      <div class="page-section pb10 student-page">
        <div
          class="container page__container d-flex align-items-center"
          v-if="getLoggedInUser.role !== USER_ROLES.STUDENT"
        >
          <p class="backResult text-50">
            <!-- <router-link v-if="$route.params.backTo" :to="{ name: 'hires' }" style="text-decoration: underline">
              <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Hires
            </router-link>
            <router-link
              v-else-if="$route.params.to === 'app-profile'"
              :to="{ name: 'app-profile', params: { id: this.$route.params.applicant_id } }"
              style="text-decoration: underline"
            >
              <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Application Profile
            </router-link> -->

            <!-- v-else-if="!$route.params.backTo" -->
            <span class="clickable-item" @click.prevent="goBack()" style="text-decoration: underline">
              <span style="font-size: 35px" class="material-icons icon-18pt text-80">navigate_before</span>
            </span>
          </p>
        </div>
        <div
          class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
        >
          <div style="position: relative; width: max-content">
            <img
              style="display: block; object-fit: cover"
              :src="student.user.avatar_url || DefaultAvatar"
              width="115"
              height="115"
              class="mb-32pt mb-md-0 bg-primary rounded mobilemb10"
              :style="student.intro_video_url ? 'opacity: 0.7' : ''"
            />
            <a href="#" @click.prevent="openVideoModal" v-if="student.intro_video_url"
              ><i
                class="fas fa-play fa-3x"
                style="position: absolute; bottom: 10px; left: 40px; right: 10px; top: 40px; color: white"
              ></i
            ></a>
          </div>

          <div class="flex mb-32pt mb-md-0 mobilemb0 ml-3">
            <h4 :class="student.quote || (lastContacted && unlockedStudent) ? 'mb-2' : 'mb-2'">
              {{ student.user.first_name }} {{ student.user.last_name }}
            </h4>
            <a
              :href="`mailto:${student.user.email}`"
              v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"
              class="mb-2 text-dark"
              >{{ student.user.email }}</a
            >
            <div>
              <b-badge
                :class="
                  'border border-' +
                    (student.is_profile_complete && student.is_profile_visible && student.user.is_active
                      ? `success badge-success`
                      : `danger badge-danger`)
                "
                variant="outlined"
                class="mt-1"
                style="font-size: 0.81rem"
                v-b-popover.hover.top
                :title="
                  student.is_profile_complete && student.is_profile_visible && student.user.is_active
                    ? ``
                    : !student.is_profile_complete && student.is_profile_visible && student.user.is_active
                    ? `This student hasn't on-boarded yet`
                    : (student.is_profile_complete && !student.is_profile_visible && student.user.is_active) ||
                      (!student.is_profile_complete && !student.is_profile_visible && student.user.is_active)
                    ? `The profile of this student is hidden`
                    : `The profile of this student is disabled`
                "
                v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"
              >
                {{
                  student.is_profile_complete && student.is_profile_visible && student.user.is_active
                    ? 'Active'
                    : !student.is_profile_complete && student.is_profile_visible && student.user.is_active
                    ? 'Incomplete'
                    : (student.is_profile_complete && !student.is_profile_visible && student.user.is_active) ||
                      (!student.is_profile_complete && !student.is_profile_visible && student.user.is_active)
                    ? 'Hidden'
                    : 'Inactve'
                }}
              </b-badge>
            </div>
            <div>
              <b-dropdown
                text="Profile Actions"
                variant="primary"
                class="my-2"
                v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"
              >
                <b-dropdown-item @click.prevent="updateStudent(student)">
                  <md-icon :class="get(student, 'user.is_active') ? 'text-danger  ' : 'text-success'">{{
                    get(student, 'user.is_active') ? 'block' : 'check_circle'
                  }}</md-icon>
                  <span :class="get(student, 'user.is_active') ? 'text-danger  ' : 'text-success'">
                    {{ get(student, 'user.is_active') ? 'Deactivate Profile' : 'Activate Profile' }}
                  </span>
                </b-dropdown-item>

                <b-dropdown-item @click.prevent="hideStudent(student)" v-if="get(student, 'user.is_active')">
                  <md-icon>{{ get(student, 'is_profile_visible') ? 'visibility_off' : 'visibility' }}</md-icon>
                  <span>
                    {{
                      get(student, 'is_profile_visible') && get(student, 'user.is_active')
                        ? 'Hide Profile'
                        : 'Show Profile'
                    }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click.prevent="openProgramModal(student)">
                  <md-icon>local_library</md-icon>
                  <span> Change Program </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="my-2" v-if="student.quote || (lastContacted && unlockedStudent)">
              <p class="d-flex align-items-center quote text-70 mb-0" v-if="student.quote">"{{ student.quote }}"</p>
              <p v-if="lastContacted && unlockedStudent" class="mb-0">
                <b>Last Contacted:</b> {{ formatDateTime(lastContacted) }}
              </p>
            </div>
            <span v-if="unlockedStudent">
              <a
                href="#"
                class="btn btn-primary mr-2"
                @click.prevent="downloadGivenFile(student.resume_url)"
                v-if="student.resume_url"
                >Resume<span class="material-icons icon-16pt ml-1">file_download</span></a
              >
              <span
                v-b-popover.hover.right
                :title="lastContacted && lastContactedInMonth ? 'You can only contact a student once a month.' : ''"
              >
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#contact-form"
                  class="btn btn-primary mr-2"
                  :class="isLastContactLoading || (lastContacted && lastContactedInMonth) ? 'disabled' : ''"
                  @click.prevent="viewContactModal"
                  v-if="
                    getLoggedInUser.role !== USER_ROLES.STUDENT && getLoggedInUser.role_type !== USER_ROLE_TYPES.SCHOOL
                  "
                  >Contact
                  <span class="material-icons icon-16pt ml-1">email</span>
                </a>
              </span>

              <router-link
                :to="{
                  name: 'make-a-hire',
                  query: {
                    school: student.school.id,
                    student: student.id
                  }
                }"
                data-toggle="modal"
                data-target="#contact-form"
                class="btn btn-primary mr-2"
                :class="isHired ? 'disabled' : ''"
                style="height: 42px"
                v-if="
                  getLoggedInUser.role !== USER_ROLES.STUDENT && getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER
                "
                >{{ isHired ? 'Already Hired' : 'Make A Hire' }}
              </router-link>
            </span>

            <span v-else-if="getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER">
              <b-skeleton-wrapper :loading="isUnlockStatusLoading">
                <template #loading>
                  <b-skeleton type="button" width="200px"></b-skeleton>
                </template>
                <span v-b-popover.hover.right="showMsg">
                  <b-btn
                    variant="secondary"
                    class="btn-normal"
                    @click.prevent="unlockStudent"
                    style="width: 200px"
                    :disabled="disableUnlock || isUnlockStatusLoading"
                  >
                    <i v-if="isUnlockStatusLoading" class="fas fa-circle-notch fa-spin"></i>
                    <span v-else><i class="material-icons icon-16pt mr-1">lock_open</i> Unlock Candidate</span>
                  </b-btn>
                </span>
              </b-skeleton-wrapper>
            </span>

            <span v-else-if="getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION">
              <b-skeleton-wrapper :loading="isOrgUnlockStatusLoading">
                <template #loading>
                  <b-skeleton type="button" width="200px"></b-skeleton>
                </template>
                <span v-b-popover.hover.right="showOrgMsg">
                  <b-btn
                    variant="secondary"
                    class="btn-normal"
                    @click.prevent="unlockOrgStudent"
                    style="width: 200px"
                    :disabled="disableOrgUnlock || isOrgUnlockStatusLoading"
                  >
                    <i v-if="isOrgUnlockStatusLoading" class="fas fa-circle-notch fa-spin"></i>
                    <span v-else><i class="material-icons icon-16pt mr-1">lock_open</i> Unlock Candidate</span>
                  </b-btn>
                </span>
              </b-skeleton-wrapper>
            </span>
          </div>
        </div>
      </div>

      <div class="container page__container">
        <b-tabs
          nav-class="nav-tabs-card bg-white px-3"
          content-class=" "
          class="mt-0"
          id="stdProfileTabs"
          style="scroll-margin-top: 66px; font-size: 16.5px"
        >
          <b-tab title="Profile" class="tab-secondary">
            <template #title>
              <i class="fas fa-user align-self-center" :class="{ 'mx-4': isTabSmallScreen }"></i>
              <strong class="ml-2" v-if="!isTabSmallScreen">Profile</strong>
            </template>

            <student-profile-tab
              :student="student"
              :careerPreferences="careerPreferences"
              :certifications="certifications"
              :showDoc="showDoc"
            />
          </b-tab>

          <b-tab
            title="Records & Credentials"
            class="tab-secondary"
            v-if="getLoggedInUser.role_type !== USER_ROLE_TYPES.STUDENT"
          >
            <template #title>
              <i class="fas fa-file align-self-center" :class="{ 'mx-4': isTabSmallScreen }"></i>
              <strong class="ml-2" v-if="!isTabSmallScreen">Records &amp; Credentials</strong>
            </template>

            <div class="page-section student-card mt-3 pt-0 mb-32pt">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex mb-1">
                    <div class="flex">
                      <div class="d-flex align-items-center mb-1">
                        <h5 class="card-title fw600">Records</h5>
                      </div>

                      <i v-if="!containPIIRecords">No Records Uploaded.</i>

                      <ul v-else>
                        <template v-for="record in recordsKeys">
                          <li :key="record" v-if="get(studentPIIs, record)">
                            <span
                              ><i class="material-icons icon-18pt mr-8pt">check</i>{{ get(piiTitles, record) }}
                              <span
                                v-if="
                                  getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL || studentPIIs[record].expire_at
                                "
                                >-</span
                              >
                              <a
                                href="#"
                                class="text-primary"
                                @click.prevent="openSecModal(get(piiTitles, record), record)"
                                v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"
                              >
                                Uploaded at
                                {{ formatDateTime(studentPIIs[record].updated_at) }}
                              </a>
                              <span v-else-if="studentPIIs[record].expire_at">
                                Expires on {{ formatDateSimple(studentPIIs[record].expire_at) }}</span
                              >
                            </span>
                          </li>
                        </template>
                      </ul>

                      <div></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-body">
                  <div class="d-flex mb-1">
                    <div class="flex">
                      <div class="d-flex align-items-center mb-1">
                        <h5 class="card-title fw600">Credentials</h5>
                      </div>

                      <i v-if="!containPIICreds">No Credentials Uploaded.</i>

                      <ul v-else>
                        <template v-for="cred in credentialsKeys">
                          <li :key="cred" v-if="get(studentPIIs, cred)">
                            <span
                              ><i class="material-icons icon-18pt mr-8pt">check</i>{{ get(piiTitles, cred) }}
                              <span
                                v-if="
                                  getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL || studentPIIs[cred].expire_at
                                "
                                >-</span
                              >
                              <a
                                href="#"
                                class="text-primary"
                                @click.prevent="openSecModal(get(piiTitles, cred), cred)"
                                v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"
                              >
                                Uploaded at
                                {{ formatDateTime(studentPIIs[cred].updated_at) }}
                              </a>
                              <span v-else-if="studentPIIs[cred].expire_at">
                                Expires on {{ formatDateSimple(studentPIIs[cred].expire_at) }}</span
                              >
                            </span>
                          </li>
                        </template>
                      </ul>

                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab
            title="Skills"
            class="tab-secondary"
            v-if="
              getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER ||
                get(this.getLoggedInUser, 'linked_entity.sch_payment_plan.title') === SCH_PAYMENT_PLANS.PAID_PLAN ||
                getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
            "
          >
            <template #title>
              <i class="material-icons align-self-center" :class="{ 'mx-4': isTabSmallScreen }">videocam</i>
              <strong class="ml-1" v-if="!isTabSmallScreen">Skills</strong>
            </template>

            <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt mt-3" style="white-space: nowrap">
              <div class="w-auto ml-sm-auto mb-sm-0 justify-content-end">
                <div class="custom-select-icon">
                  <b-form-select
                    class="custom-select-icon__select"
                    style="min-width: 185px"
                    v-model="filters.skill"
                    :options="skillOptions"
                    @change="onSkillsFilterApplied"
                  ></b-form-select>
                  <span class="material-icons custom-select-icon__icon">sort</span>
                </div>
              </div>
            </div>

            <div class="mt-3 pt-0">
              <div
                class="page-section student-card d-flex justify-content-start"
                v-if="!skills.length && !areSkillsLoading"
              >
                <div class="card card-block card-stretch card-height col-md-12">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-1">
                      <h5 class="card-title fw600">Skills</h5>
                    </div>
                    <i>No Skills Uploaded.</i>
                  </div>
                </div>
              </div>

              <b-skeleton-wrapper :loading="areSkillsLoading" v-else>
                <template #loading>
                  <div class="row">
                    <div class="col-lg-4 col-md-2 col-sm-12" v-for="item in [1, 2, 3, 4]" :key="item">
                      <div class="card card-sm card--elevated p-relative">
                        <span class="js-image">
                          <b-skeleton-img width="100%" class="pb-0 mb-0"></b-skeleton-img>
                        </span>

                        <div class="card-body">
                          <div class="d-flex">
                            <div
                              class="avatar avatar-sm rounded-circle o-hidden"
                              style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                            >
                              <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                            </div>
                            <span class="ml-2 w-100">
                              <div class="card-title"><b-skeleton width="100%" /></div>
                              <div class="text-muted"><b-skeleton width="50%"></b-skeleton></div>
                            </span>
                          </div>
                        </div>
                        <div class="card-footer">
                          <div class="row justify-content-end mr-2">
                            <b-skeleton width="20%" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <div class="row card-group-row mb-lg-12pt home-card">
                  <div v-for="skill in skills" :key="skill.id" class="col-lg-4 col-md-4 col-sm-12 clickable-item mb-3">
                    <div class="card card-sm card--elevated p-relative h-100 mb-0">
                      <span
                        class="js-image d-flex justify-content-center clickable-item overlay"
                        data-position="left"
                        :data-height="168"
                        @click.prevent="showSkillDetails(skill.id)"
                      >
                        <img
                          :src="skill.skill_thumbnail_url"
                          alt="cna"
                          class="career-card-img"
                          style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem"
                        />
                        <div class="d-flex justify-content-end">
                          <md-icon class="text-success mr-2 mt-2" style="position: absolute">{{
                            skill.approval_status === APPROVAL_STATUS.APPROVED ? 'check_circle' : ''
                          }}</md-icon>
                        </div>
                      </span>

                      <div class="card-body" @click.prevent="showSkillDetails(skill.id)">
                        <div class="d-flex">
                          <user-avatar
                            slot="aside"
                            :user="skill.student.user"
                            circle="md"
                            size="md"
                            :file-url="skill.student.user.avatar_url"
                            variant="dark"
                          >
                          </user-avatar>

                          <span class="ml-2">
                            <div class="card-title">
                              {{ skill.student.user.first_name }} {{ skill.student.user.last_name }}
                            </div>
                            <div class="text-muted">{{ skill.title }}</div>
                          </span>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="row justify-content-end">
                          <div class="col-auto d-flex align-items-center">
                            <span class="material-icons icon-16pt text-black-50 mr-4pt">access_time</span>
                            <p class="flex text-black-50 lh-1 mb-0">
                              <span>{{ formatTime(skill.created_at) }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-skeleton-wrapper>

              <pagination
                v-if="totalSkills > skills.length"
                v-model="skillsCurrPage"
                :total-rows="totalSkills"
                :per-page="perPage"
                @change="onSkillsPageChange"
                class="mb-3"
              />
            </div>
          </b-tab>

          <b-tab
            title="Simulations"
            class="tab-secondary"
            v-if="
              getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER ||
                getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
            "
          >
            <template #title>
              <i class="material-icons align-self-center" :class="{ 'mx-4': isTabSmallScreen }">mic</i>
              <strong class="ml-1" v-if="!isTabSmallScreen">Simulations</strong>
            </template>

            <div v-if="isSubmissionsLoading" class="row my-2">
              <div class="col-md-6" v-for="item in [1, 2]" :key="item">
                <div class="card">
                  <b-skeleton-img width="100%"></b-skeleton-img>

                  <div class="p-2">
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="submissions.length" class="row my-2">
              <div class="col-md-6" v-for="simulation in submissions" :key="simulation.id">
                <router-link :to="`/simulations/submissions/${simulation.id}`">
                  <div class="card clickable-item" style="height: 95%;">
                    <img
                      class=""
                      style="object-fit: cover; border-radius: 8px"
                      :src="simulation.simulation.image_url ? simulation.simulation.image_url : DefaultAvatar"
                      width="100%"
                      height="230px"
                    />
                    <div class="d-flex align-items-center p-2">
                      <img
                        class="mx-2"
                        :src="
                          get(simulation.simulation, 'posted_by.logo_url')
                            ? get(simulation.simulation, 'posted_by.logo_url')
                            : DefaultAvatar
                        "
                        width="50"
                        height="auto"
                      />

                      <div class="d-flex flex-column justify-content-center">
                        <div class="card-title">{{ simulation.simulation.title }}</div>
                        <div class="">
                          {{ truncate(simulation.simulation.instructions, { length: 50 }) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>

            <div v-else class="mt-3 pt-0">
              <div class="page-section student-card d-flex justify-content-start">
                <div class="card card-block card-stretch card-height col-md-12">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-1">
                      <h5 class="card-title fw600">Simulations</h5>
                    </div>
                    <i>No Simulation Found.</i>
                  </div>
                </div>
              </div>
            </div>

            <pagination
              v-if="totalSubmissions > submissions.length"
              v-model="currentPage"
              :total-rows="totalSubmissions"
              :per-page="perPage"
              @change="fetchSubmissions"
              class="mb-3"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <video-preview-modal
      :showModal="showVideoModal"
      :introVideo="intro_video_url"
      :stdName="get(student, 'user.first_name')"
      @close="hideVideoModal"
    />

    <school-popover
      v-if="getLoggedInUser.role_type !== USER_ROLE_TYPES.SCHOOL && !isLoading"
      :school="student.school"
      :targetId="student.id"
      placement="right"
      :active-std-count="schoolStdCount"
      :std-count-loading="stdCountLoading"
    ></school-popover>

    <document-viewer-modal
      :show-modal="showDocModal"
      :document-title="selectedDocTitle"
      :file-src="selectedDocSrc"
      @close="hideDocViewer"
    ></document-viewer-modal>

    <candidate-contact-modal
      :show-modal="showContactModal"
      :candidate="student"
      @close="hideContactModal"
      @onContact="fetchLastContacted"
      v-if="getLoggedInUser.role !== USER_ROLES.STUDENT"
    ></candidate-contact-modal>

    <secure-doc-modal
      :show-modal="showPIIDocModal"
      :document-title="selectedPIIDocTitle"
      :id="student.id"
      :document-type="selectedPIIDocType"
      @verification="tokenValidation"
      @close="hidePIIViewDocument"
      v-if="getLoggedInUser.role !== USER_ROLES.SCHOOL"
    />
    <security-modal
      :show-modal="showSecurityModal"
      :view-title="viewTitle"
      :view-id="student.id"
      :view-type="viewType"
      @close="hideSecModal"
      @validated="userValidated"
      v-if="getLoggedInUser.role !== USER_ROLES.SCHOOL"
    />

    <program-modal :showModal="showProgramModal" :student="openProgramStudent" @close="hideProgramModal" />
  </div>
</template>

<script>
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';

import {
  formatFullDate,
  getLangOptions,
  getHumanReadableCommaSepStr,
  formatDateTime,
  formatDateSimple,
  formatTime
} from '../../common/utils';
import SchoolPopover from './SchoolPopover/SchoolPopover.vue';
import { get, keyBy, map, isEmpty, truncate } from 'lodash';
import moment from 'moment';

import DefaultAvatar from '@/assets/images/people/user_avatar.png';
import UserAvatar from '@/components/User/UserAvatar.vue';
import DocumentViewerModal from './DocumentViewer/DocumentViewerModal.vue';
import CandidateContactModal from '../EmployerPortal/Students/CandidateContact/CandidateContactModal.vue';

import {
  USER_ROLES,
  USER_ROLE_TYPES,
  FACILITY_TYPES,
  STD_UNLOCK_STATUSES,
  CREDITS_REQUIREMENTS,
  APPROVAL_STATUS,
  SCH_PAYMENT_PLANS
} from '../../common/constants';
import SecureDocModal from '../SchoolPortal/SecureDocs/SecureDocModal.vue';
import SecurityModal from '../SchoolPortal/SecureDocs/SecurityModal.vue';
import VideoPreviewModal from './VideoPreviewModal.vue';
import ProgramModal from './ProgramModal.vue';
import StudentProfileTab from './StudentProfileTab.vue';
import Pagination from '@/components/Ui/Pagination.vue';

export default {
  components: {
    SchoolPopover,
    DocumentViewerModal,
    CandidateContactModal,
    UserAvatar,
    SecureDocModal,
    SecurityModal,
    VideoPreviewModal,
    Pagination,
    ProgramModal,
    StudentProfileTab
  },
  extends: Page,

  data() {
    return {
      DefaultAvatar,
      SCH_PAYMENT_PLANS,
      USER_ROLES,
      USER_ROLE_TYPES,
      CREDITS_REQUIREMENTS,
      title: '',
      studentsListLinkName: '',
      APPROVAL_STATUS,
      isLoading: true,
      isLastContactLoading: true,
      isPIILoading: false,
      student: {},
      areSkillsLoading: false,
      // TODO: Check 'Cats Acceptable' Certification.
      certificationTypes: [
        { key: 'cna_license_no', name: 'CNA', downloadable: false, showText: 'Show License' },
        { key: 'cma_license_no', name: 'CMA', downloadable: false, showText: 'Show License' }

        // TODO: Integrate in separate PII tab
        // { key: 'cpr_url', name: 'CPR Certification', downloadable: true },
        // { key: 'first_aid_url', name: 'First Aid Certification', downloadable: true },
        // { key: 'bls_url', name: 'BLS Certification (Life Support)', downloadable: true },
      ],
      recordsTypes: [{ key: 'vehicle_url', name: 'Vehicle Registration', downloadable: true }],
      showDoc: { cna_license_no: false, cma_license_no: false },
      certifications: [],
      records: [],

      skills: [],
      skillsCurrPage: 1,
      totalSkills: 0,

      careerPreferences: [],
      stdCountLoading: true,
      schoolStdCount: 0,
      perPage: 16,
      showDocModal: false,
      selectedDocTitle: '',
      selectedDocSrc: '',
      showContactModal: false,
      langOptions: [],
      FACILITY_TYPES,
      lastContacted: null,
      showProgramModal: false,

      unlockedStudent: false,
      isUnlockStatusLoading: true,
      isOrgUnlockStatusLoading: false,
      windowWidth: window.innerWidth,

      piiTypes: {
        records: [
          { type: 'state_id', name: 'State Id' },
          { type: 'social_sec_card', name: 'Social Security Card' },
          { type: 'tb_test', name: 'TB Test' },
          { type: 'covid_vacc', name: 'COVID-19 Vaccination' },
          { type: 'vehicle_reg', name: 'Vehicle Registration' }
        ],
        credentials: [
          { type: 'cpr', name: 'CPR Licence' },
          { type: 'bls', name: 'Basic Life Support (BLS) Certification' },
          { type: 'first_aid', name: 'First Aid Certification' }
        ]
      },
      recordsKeys: [
        'state_id',
        'social_sec_card',
        'green_card',
        'passport',
        'birth_cert',
        'immunization',
        'tb_test',
        'covid_vacc',
        'vehicle_reg',
        'background_check',
        'student_review',
        'rec_misc_doc_1',
        'rec_misc_doc_2',
        'rec_misc_doc_3',
        'rec_misc_doc_4',
        'rec_misc_doc_5',
        'rec_misc_doc_6',
        'rec_misc_doc_7'
      ],
      credentialsKeys: [
        'cpr',
        'bls',
        'first_aid',
        'completion_cert',
        'school_waiver',
        'ged',
        'cred_misc_doc_1',
        'cred_misc_doc_2',
        'cred_misc_doc_3',
        'cred_misc_doc_4',
        'cred_misc_doc_5',
        'cred_misc_doc_6',
        'cred_misc_doc_7'
      ],
      containPIIRecords: false,
      containPIICreds: false,
      studentPIIs: {},
      piiTitles: {},

      showPIIDocModal: false,
      showSecurityModal: false,
      selectedPIIDocTitle: null,
      selectedPIIDocType: null,

      viewTitle: null,
      viewType: null,
      isTokenExpired: false,
      intro_video_url: null,
      showVideoModal: false,
      areSkillTitlesLoaidng: false,
      skillOptions: [{ value: null, text: 'All Skills' }],
      filters: {
        skill: null
      },
      empOrgTokens: null,
      isEmpOrgTokensLoading: false,
      isEmpUnlockEnableLoading: false,
      unlock_candidate_enable: false,
      isHired: false,
      openProgramStudent: null,
      submissions: [],
      currentPage: 1,
      totalSubmissions: 0,
      isSubmissionsLoading: true
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser', 'getPiiVerificationToken']),
    ...mapGetters('credit', ['getAvailableTokens']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.title, active: true }
      ];
    },
    disableOrgUnlock() {
      return this.getAvailableTokens < 3;
    },
    calculateTokens() {
      return 3 - this.getAvailableTokens;
    },
    showOrgMsg() {
      return this.disableOrgUnlock ? `You don't have enough tokens to unlock this candidate` : '';
    },
    showMsg() {
      const areTokensAvailable = this.getAvailableTokens < 3 ? false : true;

      const message =
        this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER && !this.unlock_candidate_enable
          ? 'You need to post a job/sponsorship first.'
          : get(this.getLoggedInUser.linked_entity, 'organization') &&
            this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER &&
            !areTokensAvailable &&
            get(this.empOrgTokens, 'allow_emp_deduct') &&
            get(this.empOrgTokens, 'amount_available')
          ? `Your Tokens are lesser than the required tokens. You will be using ${this.calculateTokens} token/s of your organization.`
          : this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER && !areTokensAvailable
          ? 'you have insufficient tokens to unlock this candidate.'
          : `Contact candidate and download resume by unlocking the candidate for ${CREDITS_REQUIREMENTS.UNLOCK_STUDENT} Tokens.`;

      return message;
    },
    disableUnlock() {
      const areTokensAvailable = this.getAvailableTokens < 3 ? false : true;
      return (
        (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER && !this.unlock_candidate_enable) ||
        (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER &&
          !areTokensAvailable &&
          ((get(this.empOrgTokens, 'allow_emp_deduct') && !get(this.empOrgTokens, 'amount_available')) ||
            !get(this.empOrgTokens, 'allow_emp_deduct')))
      );
    },
    lastContactedInMonth() {
      if (!this.lastContacted) return false;

      const lastContact = moment(this.lastContacted);
      const diff = moment().diff(lastContact, 'months', true);
      return diff <= 1;
    },

    isTabSmallScreen() {
      return this.windowWidth <= 767;
    }
  },

  methods: {
    ...mapActions('student', [
      'unlockOrgCandidate',
      'getStudent',
      'getMyStudentProfile',
      'getStudentCareerPreference',
      'getStudentLastContacted',
      'getStudentUnlockedStatus',
      'unlockEmpCandidate',
      'getStudentsPIIs',
      'updateStudentProfile',
      'updateStudentHideProfile'
    ]),
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('school', ['getSchoolStudentCounts']),
    ...mapActions('credit', ['getEmployerAvailableTokens', 'updateStateAvailTokens', 'updateStateCredits']),
    ...mapActions('skills', ['getSkills', 'getSkillTitle']),
    ...mapActions('organization', ['getEmpOrgCredits', 'getEmpUnlockCandidateEnable']),
    ...mapActions('hire', ['getStudentHireStatus']),
    ...mapActions('simulations', ['getSimulationsSubmissions']),

    formatFullDate,
    formatDateTime,
    formatTime,
    formatDateSimple,
    get,
    isEmpty,
    truncate,
    goBack() {
      this.$router.go(-1);
    },

    async updateStudent(student) {
      const h = this.$createElement;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          h(
            'div',
            student.user.is_active
              ? `Deactivating profile will revoke student's access to his account.`
              : `Activating profile will grant access to student's account.`
          ),
          {
            title: 'Are you sure you want to continue?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }
        );
        if (isConfirmed) {
          const data = {
            is_active: !student.user.is_active
          };

          await this.updateStudentProfile({ id: student.id, data });
          this.makeToast({
            variant: 'success',
            msg: student.user.is_active ? 'Profile Deactivated Successfully!' : 'Profile Activated Successfully!'
          });
          this.fetchStudent();
        }
      } catch (error) {
        //
      }
    },
    async hideStudent(student) {
      const h = this.$createElement;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          h(
            'div',
            student.is_profile_visible
              ? `Hiding profile will make profile hidden to employers but student can still login.`
              : `Show profile will make student profile visible to employers.`
          ),
          {
            title: 'Are you sure you want to continue?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }
        );
        if (isConfirmed) {
          const data = {
            is_profile_visible: !student.is_profile_visible
          };

          await this.updateStudentHideProfile({ id: student.id, data });
          this.makeToast({
            variant: 'success',
            msg: 'Profile Updated Successfully!'
          });
          this.fetchStudent();
        }
      } catch (error) {
        //
      }
    },
    async fetchEmpUnlockCandidateEnable() {
      this.isEmpUnlockEnableLoading = true;
      const res = await this.getEmpUnlockCandidateEnable();
      this.unlock_candidate_enable = res.data.unlock_candidate_enable;
      this.isEmpUnlockEnableLoading = false;
    },
    async fetchEmpOrgTokens() {
      this.isEmpOrgTokensLoading = true;
      const res = await this.getEmpOrgCredits({ credits_type: 'tokens' });
      this.empOrgTokens = res.data;
      this.isEmpOrgTokensLoading = false;
    },
    onSkillsFilterApplied() {
      this.fetchSkills();
    },
    async fetchStudentHireStatus() {
      const res = await this.getStudentHireStatus({ student_id: this.$route.params.id });

      this.isHired = res.data.is_hired;
    },
    async fetchSubmissions(pageNum = 1) {
      this.isSubmissionsLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getSimulationsSubmissions({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        student__id: this.$route.params.id
      });
      this.submissions = response.data.results;
      this.currentPage = pageNum;
      this.totalSubmissions = response.data.count;
      this.isSubmissionsLoading = false;
    },
    async fetchSkills(pageNum = 1, params = {}) {
      this.areSkillsLoading = true;
      document.getElementById('app').scrollIntoView();
      document.getElementById('app').scrollIntoView();
      try {
        const response = await this.getSkills({
          limit: this.perPage,
          offset: (pageNum - 1) * this.perPage,
          student: this.$route.params.id,
          title: this.filters.skill,
          ...(this.ordering && { ordering: this.ordering }),
          ...params
        });

        this.skills = response.data.results;
        this.skillsCurrPage = pageNum;
        this.totalSkills = response.data.count;
        this.pageFromCount = (this.skillsCurrPage - 1) * this.perPage + 1;
        this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areSkillsLoading = false;
    },

    onSkillsPageChange(pageNum) {
      this.fetchSkills(pageNum);
    },

    async fetchSkillTitle() {
      this.areSkillTitlesLoaidng = true;
      try {
        const resp = (
          await this.getSkillTitle({
            ...(this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER && { school: this.student.school.id })
          })
        ).data;
        if (resp) {
          this.skillOptions = [
            { value: null, text: 'All Skills' },
            ...resp.map(skill => ({ value: skill.title, text: skill.title }))
          ];
        }
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areSkillTitlesLoaidng = false;
    },
    showSkillDetails(id) {
      this.$router.push({
        name:
          this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
            ? 'schl-student-skill-detail'
            : this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER
            ? 'emp-candidate-skill-detail'
            : 'student-skill-details',
        params: {
          id
        }
      });
    },
    openVideoModal() {
      this.showVideoModal = true;
      this.intro_video_url = this.student.intro_video_url;
    },
    hideVideoModal() {
      this.showVideoModal = false;
    },
    openProgramModal(student) {
      this.openProgramStudent = student;

      this.showProgramModal = true;
    },
    hideProgramModal(isProgramChanged) {
      this.showProgramModal = false;
      if (isProgramChanged) {
        this.fetchStudent();
      }
    },
    tokenValidation(title, type) {
      this.isTokenExpired = true;
      this.openSecModal(title, type);
    },
    userValidated(title, _id, type) {
      this.isTokenExpired = false;
      this.viewPIIDocument(title, type);
    },
    openSecModal(title, type) {
      if (!this.getPiiVerificationToken || this.isTokenExpired) {
        this.showSecurityModal = true;
        this.viewTitle = title;
        this.viewType = type;
      } else {
        this.viewPIIDocument(title, type);
      }
    },
    hideSecModal() {
      this.isTokenExpired = false;
      this.showSecurityModal = false;
      this.viewTitle = null;
      this.viewType = null;
    },
    viewPIIDocument(title, type) {
      this.selectedPIIDocTitle = title;
      this.selectedPIIDocType = type;
      this.showPIIDocModal = true;
    },
    hidePIIViewDocument() {
      this.showPIIDocModal = false;
      this.selectedPIIDocTitle = null;
      this.selectedPIIDocType = null;
    },

    async fetchLastContacted() {
      this.isLastContactLoading = true;
      const resp = await this.getStudentLastContacted({ student: this.student.id });
      this.lastContacted = get(resp, 'data.created_at', null);
      this.isLastContactLoading = false;
    },

    async fetchSchoolStdCounts() {
      this.stdCountLoading = true;
      const queryData = {
        only_recently_active: false,
        schools: [this.student.school.id]
      };
      const resp = await this.getSchoolStudentCounts({
        ...queryData
      });
      this.schoolStdCount = get(resp[0], 'students_count', 0);

      this.stdCountLoading = false;
    },
    async fetchCareerPreferences() {
      this.isLoading = true;
      const resp = await this.getStudentCareerPreference({ student: this.student.id });
      if (!isEmpty(resp)) {
        this.careerPreferences = resp.career_preferences;
      }
      this.isLoading = false;
    },

    async fetchStudentPIIs() {
      this.isPIILoading = false;
      try {
        const resp = await this.getStudentsPIIs({ student_id: [parseInt(this.$route.params.id)] });
        const piis = get(resp, 'data.0.student_piis', []);
        this.studentPIIs = keyBy(piis, 'type');
        this.piiTitles = get(resp, 'data.0.type_title_dict', []);

        const includedPIIs = map(piis, 'type');
        this.containPIIRecords = this.piiTypes.records.some(rec => includedPIIs.includes(rec.type));
        this.containPIICreds = this.piiTypes.credentials.some(cred => includedPIIs.includes(cred.type));

        this.isPIILoading = true;
      } catch (error) {
        //
      }
    },

    async fetchStdUnlockedStatus() {
      this.isUnlockStatusLoading = true;
      if (
        this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER ||
        this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
      ) {
        const resp = await this.getStudentUnlockedStatus({ student: this.$route.params.id });
        this.unlockedStudent = get(resp, 'data.status', STD_UNLOCK_STATUSES.LOCKED) === STD_UNLOCK_STATUSES.UNLOCKED;
        if (this.unlockedStudent) {
          this.student['resume_url'] = get(resp, 'data.unlocked_data.resume_url');
        }
      } else {
        this.unlockedStudent = true;
      }
      this.isUnlockStatusLoading = false;
    },
    async unlockOrgStudent() {
      this.isOrgUnlockStatusLoading = true;
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `You are about to unlock the candidate for ${CREDITS_REQUIREMENTS.UNLOCK_STUDENT} Tokens. Proceed?`,
        {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }
      );
      if (isConfirmed) {
        try {
          await this.unlockOrgCandidate({ data: { student: this.$route.params.id } });
          this.unlockedStudent = true;
          this.updateStateAvailTokens();
          this.updateStateCredits();
          this.makeToast({ variant: 'success', msg: 'Candidate successfully unlocked' });
        } catch (e) {
          this.makeToast({
            variant: 'danger',
            msg: 'Cannot unlock candidate. Please try again later or contact admin.'
          });
        }
      }

      this.isOrgUnlockStatusLoading = false;
    },

    async unlockStudent() {
      this.isUnlockStatusLoading = true;

      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `You are about to unlock the candidate for ${CREDITS_REQUIREMENTS.UNLOCK_STUDENT} Tokens. Proceed?`,
        {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }
      );
      if (isConfirmed) {
        try {
          const response = await this.getEmployerAvailableTokens();
          const availableTokens = get(response, 'available_tokens', 0);

          if (
            availableTokens >= CREDITS_REQUIREMENTS.UNLOCK_STUDENT ||
            (get(this.empOrgTokens, 'allow_emp_deduct') && get(this.empOrgTokens, 'amount_available'))
          ) {
            const resp = await this.unlockEmpCandidate({ data: { student: this.$route.params.id } });
            this.student['resume_url'] = get(resp, 'data.unlocked_data.resume_url');
            this.unlockedStudent = true;

            this.updateStateAvailTokens();
            this.updateStateCredits();
            this.makeToast({ variant: 'success', msg: 'Candidate successfully unlocked' });
          } else {
            this.makeToast({
              variant: 'danger',
              msg: 'Insufficient tokens. Please purchase more tokens to unlock a candidate.'
            });
          }
        } catch (_err) {
          this.makeToast({
            variant: 'danger',
            msg: 'Cannot unlock candidate. Please make sure you have sufficient tokens available.'
          });
        }
      }

      this.isUnlockStatusLoading = false;
    },

    async fetchStudent() {
      this.isLoading = true;
      try {
        let resp;
        if (this.getLoggedInUser.role === USER_ROLES.STUDENT) {
          resp = await this.getMyStudentProfile(true);
        } else {
          resp = (await this.getStudent(this.$route.params.id)).data;
        }

        // Redirect paused/incomplete/inactive profile to main candidates page.
        if (
          ((!resp.is_profile_visible && this.getLoggedInUser.role !== USER_ROLES.STUDENT) ||
            (!resp.is_profile_complete && this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) ||
            !resp.user.is_active) &&
          this.getLoggedInUser.role_type !== USER_ROLE_TYPES.SCHOOL
        ) {
          this.$router.push({
            name: this.studentsListLinkName,
            params: {
              ...(this.$route.params.to === 'unlock' && { to: 'unlock' }),
              ...(this.$route.params.to === 'org-unlock' && { to: 'org-unlock' })
            }
          });
        }

        resp.desired_work_type = getHumanReadableCommaSepStr(
          resp.desired_work_type.map(type => this.$t(`studentWorkTypeOpts.${type}`)),
          'or'
        );
        resp.desired_work_time = getHumanReadableCommaSepStr(
          resp.desired_work_time.map(time => this.$t(`studentWorkTimeOpts.${time}`))
        );
        resp.desired_work_day = getHumanReadableCommaSepStr(
          resp.desired_work_day.map(day => this.$t(`jobWorkDays.${day}`))
        );
        const arr = getLangOptions();
        const langPreference = keyBy(arr, 'value');
        resp.lang_preferences = resp.lang_preferences.map(lang => langPreference[lang].name);

        resp.interested_facility_types = resp.interested_facility_types.map(type => this.FACILITY_TYPES[type]);

        this.certificationTypes.forEach(certType => {
          if (resp[certType.key]) {
            this.certifications.push(certType);
          }
        });
        this.recordsTypes.forEach(recordType => {
          if (resp[recordType.key]) {
            this.records.push(recordType);
          }
        });
        resp.grad_date = resp.grad_date ? moment(resp.grad_date).format('MMMM, YYYY') : null;

        this.student = resp;
        this.fetchSchoolStdCounts();
        this.fetchStdUnlockedStatus();
      } catch (_err) {
        this.$router.push({
          name: this.studentsListLinkName,
          params: {
            ...(this.$route.params.to === 'unlock' && { to: 'unlock' }),
            ...(this.$route.params.to === 'org-unlock' && { to: 'org-unlock' })
          }
        });
      }
      this.isLoading = false;
    },

    async downloadGivenFile(url) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left'
      });

      await this.downloadFile({ fileUrl: url, removeTimestamp: true });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left'
      });
    },

    toggleDocValue(key) {
      this.showDoc[key] = !this.showDoc[key];
    },

    viewDocument(title, src) {
      this.selectedDocTitle = title;
      this.selectedDocSrc = src;
      this.showDocModal = true;
    },

    hideDocViewer() {
      this.showDocModal = false;
      this.selectedDocTitle = '';
      this.selectedDocSrc = '';
    },

    viewContactModal() {
      this.showContactModal = true;
    },

    hideContactModal() {
      this.showContactModal = false;
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) {
      this.fetchEmpUnlockCandidateEnable();
      this.fetchStudentHireStatus();
    }

    if (
      this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER &&
      this.getLoggedInUser.linked_entity.organization
    ) {
      this.fetchEmpOrgTokens();
    }
    if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL) {
      this.title = 'Student Profile';
      this.studentsListLinkName = 'schl-students-list';
    } else {
      this.title = 'Candidate Profile';
      if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION) {
        this.studentsListLinkName = 'org-students-list';
      } else {
        this.studentsListLinkName =
          this.$route.params.to === 'unlock'
            ? 'employer-unlocked-candidates-list'
            : this.$route.params.to === 'org-unlock'
            ? 'org-unlock-students-list'
            : 'employer-candidates-list';
      }
    }

    await this.fetchStudent();
    if (
      this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER ||
      this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ||
      this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
    ) {
      this.fetchStudentPIIs();
    }
    if (
      this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER ||
      this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
    ) {
      this.fetchLastContacted();
      this.fetchSubmissions();
    }
    if (
      this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER ||
      get(this.getLoggedInUser, 'linked_entity.sch_payment_plan.title') === SCH_PAYMENT_PLANS.PAID_PLAN ||
      this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
    ) {
      this.fetchSkillTitle();
      this.fetchSkills();
    }
    this.fetchCareerPreferences();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
