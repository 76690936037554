<template>
  <div class="page-section student-card mt-3 pt-0 mb-32pt">
    <div class="card">
      <div class="card-body">
        <div class="d-flex mb-1">
          <div class="flex">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Availability</h5>
            </div>

            <div class="d-flex">
              <div class="flex">
                <h6 class="icon-align" v-if="student.city || student.state || student.work_travel">
                  <i class="material-icons icon-18pt mr-8pt">location_on</i>Lives near {{ student.city }},
                  {{ student.state }} • Willing to travel {{ student.work_travel }} miles
                </h6>
                <h6 class="icon-align" v-if="student.desired_work_type">
                  <i class="material-icons icon-18pt mr-8pt">timelapse</i>Seeking
                  {{ student.desired_work_type }}
                </h6>
                <h6 class="icon-align" v-if="student.desired_work_time">
                  <i class="material-icons icon-18pt mr-8pt">access_time</i>Available at
                  {{ student.desired_work_time }}
                </h6>
                <h6 class="icon-align" v-if="student.desired_work_day">
                  <i class="material-icons icon-18pt mr-8pt">event</i>Available on
                  {{ student.desired_work_day }}
                </h6>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="student.user.bio">
      <div class="card-body">
        <div class="d-flex mb-1">
          <div class="flex">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">BIO</h5>
            </div>

            <h6>
              {{ student.user.bio }}
            </h6>

            <div></div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="d-flex mb-1">
          <div class="flex">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">EDUCATION</h5>
            </div>

            <div class="d-flex">
              <div class="flex">
                <h6 class="icon-align">
                  <i class="material-icons icon-18pt mr-8pt">school</i
                  ><a
                    style="display: inline; font-weight: 600"
                    href="#"
                    @click.prevent
                    class="mr-2 text-primary"
                    :id="`school-popover-${$route.params.id}`"
                    >{{ student.school.name }}</a
                  >
                </h6>
                <h6 class="icon-align" v-if="student.grad_date">
                  <i class="material-icons icon-18pt mr-8pt">event</i>{{ student.grad_date }}
                </h6>
                <h6 class="icon-align" v-if="student.enrolled_program">
                  <i class="material-icons icon-18pt mr-8pt">local_library</i>{{ student.enrolled_program.title }}
                  <span class="ml-1" v-if="student.selected_tag"> - {{ student.selected_tag.title }}</span>
                </h6>
                <h6 class="icon-align" v-if="student.ged_or_diploma !== undefined">
                  <i class="material-icons icon-18pt mr-8pt">school</i>High School Diploma / GED:
                  {{ student.ged_or_diploma ? 'Yes' : 'No' }}
                  <span class="ml-1" v-if="student.diploma_date">
                    - Graduation: {{ student.diploma_date.split('-')[0] }}
                  </span>
                </h6>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row card-group-row">
      <div class="col-sm-5 col-xl-5">
        <div class="card card-sm">
          <div class="card-body">
            <div class="">
              <div class="">
                <div class="">
                  <h5 class="card-title fw600">Care Experience</h5>
                </div>
                <h6 class="icon-align" v-if="student.work_experience">
                  <i class="material-icons icon-18pt mr-8pt">favorite</i
                  ><span v-if="student.work_experience">{{ student.work_experience }} Year(s)</span
                  ><span v-else>None</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-7 col-xl-7">
        <div class="card card-sm">
          <div class="card-body">
            <div class="">
              <div class="">
                <div class="">
                  <h5 class="card-title fw600">LAST EMPLOYER</h5>
                  <h6>
                    <h6 class="icon-align">
                      <i class="material-icons icon-18pt mr-8pt">business</i>{{ student.curr_employer || 'None' }}
                    </h6>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="d-flex mb-1">
          <div class="flex">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Licenses</h5>
            </div>

            <i v-if="!certifications.length">No Licenses Present.</i>

            <ul v-else>
              <li v-for="cert in certifications" :key="cert.key">
                <a
                  href="#"
                  class="text-primary"
                  @click.prevent="viewDocument(cert.name, student[cert.key])"
                  v-if="cert.downloadable"
                >
                  <i class="material-icons icon-18pt mr-8pt">assignment_turned_in</i>{{ cert.name }}
                </a>
                <span v-else
                  ><i class="material-icons icon-18pt mr-8pt">assignment_turned_in</i>{{ cert.name }} -
                  <a
                    href="#"
                    class="text-primary"
                    @click.prevent="toggleDocValue(cert.key)"
                    v-if="!showDoc[cert.key]"
                    >{{ cert.showText || 'Show' }}</a
                  >
                  <span v-else>{{ student[cert.key] }}</span></span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Facility Interest</h5>
            </div>

            <i v-if="!student.interested_facility_types.length">No facility interests added.</i>

            <ul v-else>
              <li v-for="(facility, index) in student.interested_facility_types" :key="index">
                <i class="material-icons icon-18pt mr-8pt">check_circle</i> {{ facility }}
              </li>
            </ul>

            <div></div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="d-flex mb-1">
          <div class="flex">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Career Interest</h5>
            </div>

            <i v-if="!careerPreferences.length">No career interests added.</i>

            <ul v-else>
              <li v-for="careerPreference in careerPreferences" :key="careerPreference.key">
                <i class="material-icons icon-18pt mr-8pt">check_circle</i> {{ careerPreference.name }}
              </li>
            </ul>

            <div></div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Languages</h5>
            </div>

            <i v-if="!student.lang_preferences.length">No language added.</i>

            <ul v-else>
              <li v-for="(lang, index) in student.lang_preferences" :key="index">
                <i class="material-icons icon-18pt mr-8pt">language</i> {{ lang }}
              </li>
            </ul>

            <div></div>
          </div>
        </div>
      </div>
    </div>

    <!-- TODO: Use skills if needed. -->
    <!-- <div class="card" v-if="skills.length">
        <div class="card-body">
          <div class="d-flex mb-1">
            <div class="flex">
              <div class="d-flex align-items-center mb-1">
                <h5 class="card-title fw600">Skills</h5>
              </div>

              <ul>
                <li v-for="skill in skills" :key="skill.key">
                  <i class="material-icons icon-18pt mr-8pt">volunteer_activism</i>{{ skill.name }}
                </li>
              </ul>

              <div></div>
            </div>
          </div>
        </div>
      </div> -->
  </div>
</template>

<script>
import { get } from 'lodash';
export default {
  props: {
    student: { type: Object, default: () => {} },
    certifications: { type: Array, default: () => [] },
    careerPreferences: { type: Array, default: () => [] },
    showDoc: { type: Object, default: () => {} }
  },

  data() {
    return {
      get
    };
  }
};
</script>

<style></style>
