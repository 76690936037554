<template>
  <div>
    <div :class="containerClass">
      <!-- <page-header :title="title" :container-class="containerClass" /> -->
      <div class="d-flex justify-content-center" v-if="getLoggedInUser.role === USER_ROLES.STUDENT">
        <div class="card card-block card-stretch card-height col-md-12">
          <div class="card-body text-center">
            <p class="text-90">
              Welcome to skills practice! Click below to upload video of yourself performing a skill that you've learned
              in the program.
            </p>
            <b-btn @click.prevent="openSkillModal()" variant="primary"
              ><i class="material-icons mr-1">videocam</i>Practice Skill</b-btn
            >
          </div>
        </div>
      </div>

      <!-- <div class="page-section"> -->
      <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
        <h2 class="mb-0 d-sm-block" v-if="!isMobSmallScreen">
          Skills
          <i
            class="material-icons icon-16pt clickable-item"
            v-b-popover.hover.top="'Explore the skills of your students.'"
            >info</i
          >
        </h2>
        <div class="w-auto ml-sm-auto mb-sm-0 justify-content-end">
          <div class="custom-select-icon">
            <b-form-select
              class="custom-select-icon__select"
              style="min-width: 185px"
              v-model="filters.skill"
              :options="skillOptions"
              @change="onFilterApplied"
            ></b-form-select>
            <span class="material-icons custom-select-icon__icon">sort</span>
          </div>
        </div>
      </div>

      <page-separator
        :title="
          !isLoading ? `showing ${totalSkills ? `${pageFromCount}-${pageToCount}` : 0} of ${totalSkills} Skills` : ''
        "
      />

      <div class="page-section d-flex justify-content-center" v-if="!skills.length && !isLoading">
        <div class="card card-block card-stretch card-height col-md-8">
          <div class="card-body text-center">
            <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
            <h4>No Skills Found</h4>

            <p class="text-muted">
              Couldn't find any skills. Publish your skills practice video now and showcase your CNA skills!
            </p>
          </div>
        </div>
      </div>

      <b-skeleton-wrapper :loading="isLoading" v-else>
        <template #loading>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6" v-for="item in [1, 2, 3]" :key="item">
              <div class="card card-sm card--elevated p-relative">
                <span class="js-image">
                  <b-skeleton-img width="100%" class="pb-0 mb-0"></b-skeleton-img>
                </span>

                <div class="card-body">
                  <div class="d-flex">
                    <!-- <div class="flex"> -->
                    <div
                      class="avatar avatar-sm rounded-circle o-hidden"
                      style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                    >
                      <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                    </div>
                    <span class="ml-2 w-100">
                      <div class="card-title"><b-skeleton width="100%" /></div>
                      <div class="text-muted"><b-skeleton width="50%"></b-skeleton></div>
                    </span>
                    <!-- <a class="card-title" href="#" @click.prevent><b-skeleton width="70%"></b-skeleton></a>
                    <small class="text-50 font-weight-bold mb-4pt"><b-skeleton width="50%"></b-skeleton></small> -->
                    <!-- </div> -->
                    <!-- <a href="#" class="ml-4pt material-icons text-20 card-course__icon-favorite" @click.prevent
                      >more_vert</a
                    > -->
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row justify-content-end mr-2">
                    <!-- <div class="col-auto d-flex align-items-center"> -->
                    <!-- <span class="material-icons icon-16pt text-black-50 mr-4pt">access_time</span>
                      <p class="flex text-black-50 lh-1 mb-0"> -->
                    <b-skeleton width="20%" />
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="row card-group-row mb-lg-12pt home-card">
          <div
            v-for="skill in skills"
            :key="skill.id"
            :id="`skill-${skill.id}`"
            class="col-lg-4 col-md-4 col-sm-6 clickable-item mb-3"
          >
            <div class="card card-sm card--elevated p-relative h-100 mb-0" style="scroll-margin-top: 70px">
              <span
                class="js-image d-flex justify-content-center clickable-item overlay"
                style="width: 100%"
                data-position="left"
                :data-height="168"
                @click.prevent="showSkillDetails(skill.id)"
              >
                <img
                  :src="skill.skill_thumbnail_url"
                  alt="cna"
                  style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem"
                  class="career-card-img"
                />
                <div class="d-flex justify-content-end">
                  <md-icon class="text-success mr-2 mt-2" style="position: absolute">{{
                    skill.approval_status === APPROVAL_STATUS.APPROVED ? 'check_circle' : ''
                  }}</md-icon>
                </div>
              </span>

              <div class="card-body py-3 my-0" @click.prevent="showSkillDetails(skill.id)">
                <div class="d-flex">
                  <!-- <div class="flex"> -->
                  <!-- <a href="#" @click.prevent="showSkillDetails(skill.id, skill.skill_video_url)"> -->
                  <user-avatar
                    slot="aside"
                    :user="skill.student.user"
                    circle="md"
                    size="md"
                    :file-url="skill.student.user.avatar_url"
                    variant="dark"
                  >
                  </user-avatar>
                  <!-- </a> -->
                  <!-- </div> -->
                  <span class="ml-2">
                    <div class="card-title">{{ skill.student.user.first_name }} {{ skill.student.user.last_name }}</div>
                    <div class="text-muted">{{ skill.title }}</div>
                  </span>
                </div>
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <span class="material-icons icon-16pt text-black-50 mr-4pt">comment</span>
                    <p class="flex text-black-50 lh-1 mb-0">
                      <span>{{ $n(get(commentsCount[skill.id], 'comments_count', 0)) }}</span>
                    </p>

                    <a
                      class="ml-2 text-black-50 mr-4pt"
                      @click.prevent="get(likesCount[skill.id], 'mylike') ? unlikeSkill(skill.id) : likeSkill(skill.id)"
                      ><span
                        class="material-icons icon-16pt"
                        :class="{ 'text-primary': get(likesCount[skill.id], 'mylike') }"
                        >thumb_up</span
                      >
                      <span :class="{ 'text-primary': get(likesCount[skill.id], 'mylike') }">
                        {{ $n(get(likesCount[skill.id], 'like_count', 0)) }}
                      </span>
                    </a>
                  </div>

                  <div class="d-flex align-items-center">
                    <span class="material-icons icon-16pt text-black-50 mr-4pt">access_time</span>
                    <p class="flex text-black-50 lh-1 mb-0">
                      <span>{{ formatTime(skill.created_at) }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-skeleton-wrapper>

      <pagination
        v-if="totalSkills > skills.length"
        v-model="currentPage"
        :total-rows="totalSkills"
        :per-page="perPage"
        @change="onPageChange"
        class="mb-3"
      />
    </div>
    <skill-privacy-modal :show-modal="showPrivacyModal" @close="hideSkillModal" />
  </div>
  <!-- </div> -->
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import UserAvatar from '@/components/User/UserAvatar.vue';
import { get, keyBy, update } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { formatTime } from '../../../common/utils';
import { USER_ROLES, APPROVAL_STATUS } from '../../../common/constants';
import SkillPrivacyModal from './SkillPrivacyModal.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: {
    Pagination,
    UserAvatar,
    SkillPrivacyModal,
    PageSeparator,
  },
  extends: Page,

  data() {
    return {
      title: 'Skills',

      USER_ROLES,
      APPROVAL_STATUS,
      totalSkills: 0,
      stdSkills: [],
      skills: [],
      isSaving: false,
      isLoading: false,
      perPage: 16,
      areStdSkillsLoaidng: false,
      areSkillTitlesLoaidng: false,
      commentCountLoading: false,
      commentsCount: 0,
      skillOptions: [{ value: null, text: 'All Skills' }],
      filters: {
        skill: null,
      },
      showPrivacyModal: false,
      windowWidth: window.innerWidth,
      likeCountLoading: false,
      likesCount: 0,
      isLikingVideo: false,
      isUnlikingVideo: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Skills', active: true },
      ];
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },

  methods: {
    ...mapActions('skills', ['getSkillVideo', 'getSkillTitle', 'getSkills', 'getStudentSkills']),
    ...mapActions('comments', ['getComments', 'getCommentCount']),
    ...mapActions('likes', ['getLikesCount', 'createLike', 'unLike']),
    formatTime,
    get,
    async unlikeSkill(id) {
      if (this.isLikingVideo || this.isUnlikingVideo) return;

      this.isUnlikingVideo = true;
      try {
        const data = { like_on_id: id, like_on_type: 'student_skill_video' };

        update(this.likesCount, `${id}.mylike`, () => false);

        await this.unLike(data);
        // this.makeToast({ variant: 'success', msg: 'Skill UnLiked!' });

        this.fetchLikeCount();
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isUnlikingVideo = false;
    },

    async likeSkill(id) {
      if (this.isLikingVideo || this.isUnlikingVideo) return;

      this.isLikingVideo = true;
      try {
        const data = { like_on_id: id, like_on_type: 'student_skill_video' };

        update(this.likesCount, `${id}.mylike`, () => true);

        await this.createLike(data);
        // this.makeToast({ variant: 'success', msg: 'Skill Liked!' });
        this.fetchLikeCount();
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLikingVideo = false;
    },
    openSkillModal() {
      this.showPrivacyModal = true;
    },
    hideSkillModal() {
      this.showPrivacyModal = false;
    },
    async fetchCommentCount() {
      this.commentCountLoading = true;
      const ids = this.skills.map((comment) => comment.id);
      const resp = await this.getCommentCount({
        commented_on_type: 'studentskillvideo',
        commented_on_object_ids: ids,
      });
      this.commentsCount = keyBy(resp.data.count, 'comment_on_id');
      this.commentCountLoading = false;
    },
    async fetchLikeCount() {
      this.likeCountLoading = true;
      const ids = this.skills.map((comment) => comment.id);
      const resp = await this.getLikesCount({
        like_on_type: 'studentskillvideo',
        like_on_object_ids: ids,
      });
      this.likesCount = keyBy(resp.data.count, 'like_on_id');
      this.likeCountLoading = false;
    },
    async fetchSkills(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();
      try {
        const response = await this.getSkills({
          limit: this.perPage,
          offset: (pageNum - 1) * this.perPage,
          title: this.filters.skill,
          ...(this.ordering && { ordering: this.ordering }),
          ...params,
        });

        this.skills = response.data.results;
        this.currentPage = pageNum;
        this.totalSkills = response.data.count;
        this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
        this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;

        if (this.$route.params.id) {
          this.$nextTick(() => {
            const cm = document.getElementById(`skill-${this.$route.params.id}`);
            cm.scrollIntoView({ behavior: 'smooth' });
          });
        }
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    showSkillDetails(id) {
      this.$router.push({ name: 'student-skill-details', params: { id } });
    },
    onFilterApplied() {
      this.fetchSkills();
    },

    async fetchSkillTitle() {
      this.areSkillTitlesLoaidng = true;
      try {
        const resp = (await this.getSkillTitle()).data;
        if (resp) {
          this.skillOptions = [
            { value: null, text: 'All Skills' },
            ...resp.map((skill) => ({ value: skill.title, text: skill.title })),
          ];
        }
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areSkillTitlesLoaidng = false;
    },

    onPageChange(pageNum) {
      this.fetchSkills(pageNum);
    },

    // async fetchStudentSkills() {
    //   this.areStdSkillsLoaidng = true;
    //   try {
    //     const resp = (await this.getStudentSkills()).data;
    //     this.stdSkills = resp;
    //   } catch (err) {
    //     this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    //   }
    //   this.areStdSkillsLoaidng = false;
    // },
  },

  async mounted() {
    if (this.$route.query.uploadSuccess) {
      this.makeToast({
        variant: 'success',
        msg: 'Your video is being uploaded. It will appear in the feed when ready.',
      });
    }

    await this.fetchSkills();
    this.fetchCommentCount();
    this.fetchSkillTitle();
    this.fetchLikeCount();
    // this.fetchStudentSkills();
  },
};
</script>
