<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <h3 class="text-center mb-1">
      Account details confirmed! When is your expected completion date for the {{ programName }} program?
    </h3>
    <p class="text-center mb-5">
      Your completion date is the day that you have completed your last day of training and received your Certificate of
      Completion.
    </p>
    <b-form-group :label="`Expected Completion Date at ${schoolName}`" label-for="gradDate" label-class="form-label">
      <date-picker
        v-model="profile.grad_date"
        type="month"
        format="MMMM, YYYY"
        style="width: 100%"
        value-type="date"
        id="grad_date"
        lang="en"
        placeholder="No Date Selected"
        class="w-100"
        :input-class="`mx-input ${
          !$v.profile.grad_date.required && $v.profile.grad_date.$dirty ? 'form-control is-invalid' : ''
        }`"
      ></date-picker>
      <b-form-invalid-feedback :state="!$v.profile.grad_date.required && $v.profile.grad_date.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>
  </b-form>
</template>

<script>
import { pick } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
export default {
  components: { DatePicker },
  props: {
    profileData: { type: Object, required: true },
    schoolName: { type: String, required: true },
    programName: { type: String, required: true },
  },
  data() {
    return {
      profile: {
        grad_date: null,
      },
    };
  },
  validations() {
    return {
      profile: {
        grad_date: { required },
      },
    };
  },

  watch: {
    profileData: {
      handler() {
        this.profile = pick(this.profileData, ['grad_date']);
      },

      deep: true,
    },
  },
  methods: {
    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { profile: { ...this.profile } },
      };
    },
  },
};
</script>

<style>
</style>